import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import userImage from '../../assets/icons/OBJECTS.svg';
import { Dialog } from 'primereact/dialog';
import CommonConfig from '../../utils/constant';
import api from '../../utils/apiClient';
import APIConstant from '../../utils/pathConstants';
import Toast from '../Shared/Toast/Toast';
import Loader from '../Shared/Loader/Loader';
import { apiBase } from '../../utils/config';

const Header = (props) => {
    const navigate = useNavigate();
    const op = useRef(null);
    const overlayPanel = useRef(null);
    const openGeneralInfo = useRef(null);
    const [permissionList, setPermissionList] = useState([]);
    const [notificationList, setNotificationList] = useState([]);
    const [notificationCount, setNotificationCount] = useState(0);
    const [notificationType, setNotificationType] = useState('');
    const [openNotification, setOpenNotification] = useState(null);
    const [openRequestNotification, setOpenRequestNotification] = useState(null);
    const [notificationDetailArray, setNotificationDetailArray] = useState([]);
    const [project, setProject] = useState('');
    const [isChangePassword, setIsPasswordChange] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [projectErr, setProjectErr] = useState(false);
    const [projectErrText, setProjectErrText] = useState('');
    const [oldPasswordErr, setOldPasswordErr] = useState(false);
    const [oldPasswordErrText, setOldPasswordErrText] = useState('');
    const [newPasswordErr, setNewPasswordErr] = useState(false);
    const [newPasswordErrText, setNewPasswordErrText] = useState('');
    const [confirmPasswordErr, setConfirmPasswordErr] = useState(false);
    const [confirmPasswordErrText, setConfirmPasswordErrText] = useState('');
    const [profilePath, setProfilePath] = useState(undefined);
    const [userData, setUserData] = useState({});

    useEffect(() => {
        setInterval(() => {
            const loginData = CommonConfig.loginData();
            GetUserDetail(loginData?._id, loginData?.Token);
        }, 5000);
    }, [])

    const GetUserDetail = async (userid, userToken) => {
        try {
            let data = {
                id: userid,
            }
            await api.post(APIConstant.path.getUserById, data).then(async response => {
                let res = response;
                if (res.success) {
                    if (res?.data?.Token !== userToken) {
                        navigate('/login');
                        localStorage.clear();
                    } else {
                        setUserData(res?.data);
                        setProfilePath(res?.data?.ProfilePhotoUrl ? `${apiBase}/UserProfilePic/${res?.data?.ProfilePhotoUrl}` : null)
                    }
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const handleCloseNotification = (type) => {
        if (type === "notification") {
            setOpenNotification(null);
            setNotificationList([]);
            setNotificationType('');
            setNotificationCount(0);
        } else {
            setOpenRequestNotification(null);
        }
    };

    const toggleOverlay = (e) => {
        openGeneralInfo.current.toggle(e);
    };

    const handleLogout = async () => {
        try {
            let data = {
                id: userData?._id
            };
            Loader.show();

            const res = await api.post(APIConstant.path.logout, data);
            if (res.success) {
                navigate('/login');
                localStorage.clear();
                Loader.hide();
                Toast.success({ message: 'Logged out successfully' });
            } else {
                Toast.error({ message: res.message });
                Loader.hide();
            }
        } catch (err) {
            Loader.hide();
        }
    };

    const handleCheck = (e, type) => {
        if (type === 'project') {
            if (!e.target.value) {
                setProjectErr(true);
                setProjectErrText("Project is required");
            } else {
                setProjectErr(false);
                setProjectErrText('');
            }
            setProject(e.target.value);
        }

        if (type === 'oldPassword') {
            if (!e.target.value) {
                setOldPasswordErr(true);
                setOldPasswordErrText("Old password is required");
            } else {
                setOldPasswordErr(false);
                setOldPasswordErrText('');
            }
            setOldPassword(e.target.value);
        }

        if (type === 'newPassword') {
            if (!e.target.value) {
                setNewPasswordErr(true);
                setNewPasswordErrText("New password is required");
            } else {
                setNewPasswordErr(false);
                setNewPasswordErrText('');
            }
            setNewPassword(e.target.value);
        }

        if (type === 'confirmPassword') {
            if (!e.target.value) {
                setConfirmPasswordErr(true);
                setConfirmPasswordErrText("Confirm password is required");
            } else if (newPassword !== e.target.value) {
                setConfirmPasswordErr(true);
                setConfirmPasswordErrText('Confirm password does not match');
            } else {
                setConfirmPasswordErr(false);
                setConfirmPasswordErrText('');
            }
            setConfirmPassword(e.target.value);
        }
    };

    const validate = () => {
        let isValid = true;

        if (!newPassword) {
            setNewPasswordErr(true);
            setNewPasswordErrText('Please enter new password');
            isValid = false;
        }

        if (!confirmPassword) {
            setConfirmPasswordErr(true);
            setConfirmPasswordErrText('Please enter new password');
            isValid = false;
        }

        if (newPassword !== confirmPassword) {
            setConfirmPasswordErr(true);
            setConfirmPasswordErrText('Confirm password does not match');
            isValid = false;
        }

        return isValid;
    };

    const changePassword = async () => {
        if (validate()) {
            try {
                let userData = CommonConfig.loginData();
                const data = { UserID: CommonConfig.loginData().UserId, NewPassword: newPassword };
                const response = await api.post(APIConstant.path.userChangePassword, data);
                if (response.success) {
                    Toast.success({ message: response.message });
                    CommonConfig.getUserMenu(userData, navigate);
                } else {
                    Toast.error({ message: response.message });
                }
            } catch (err) {
                console.error(err);
            }
        }
    };

    const onError = () => {
        setProfilePath(undefined);
    };

    return (
        <div className='dashboard-header'>
            <div className='p-d-lg-flex p-jc-between'>
                <div className='p-w-100'>
                    <div className='main-top-bar'>
                        <div></div>
                        <div className='header-right-action p-ml-auto'>
                            <OverlayPanel ref={op} id="overlay_panel" style={{ width: '450px' }} className="profile-menu">
                                <div className='profile-menu-container'>
                                    <ul className='menu-options'>
                                        <li className='menu-link' onClick={() => props.navigate({ pathname: '/edit-profile' })}>
                                            <i className='icon-edit-profile'></i>
                                            <span>Edit Profile</span>
                                            <label className='list-dot'></label>
                                        </li>
                                        <li className='menu-link' onClick={() => setIsPasswordChange(true)}>
                                            <i className='icon-change-password'></i>
                                            <span>Change Password</span>
                                            <label className='list-dot'></label>
                                        </li>
                                        <li className='menu-link' onClick={handleLogout}>
                                            <i className='icon-logout'></i>
                                            <span>Logout</span>
                                            <label className='list-dot'></label>
                                        </li>
                                    </ul>
                                </div>
                            </OverlayPanel>

                            <div className="header-bar">
                                <div className="right-nav-buttons p-d-flex">
                                    <OverlayPanel
                                        ref={overlayPanel}
                                        showCloseIcon={false}
                                        dismissable
                                        onHide={(e) => handleCloseNotification(e, "notification")}
                                        className='notification-general-admin'
                                    >
                                        <div className="p-d-flex p-ai-center noti-drop-container">
                                            <div className="notification-number">{notificationCount}</div>
                                            <div className="noti-input">
                                                <Dropdown
                                                    className='p-w-100'
                                                    value={notificationType}
                                                    options={permissionList}
                                                    onChange={(e) => {
                                                        setNotificationType(e.target.value);
                                                        // getNotificationList(state.UserID, e.target.value);
                                                    }}
                                                    optionLabel="name"
                                                    placeholder="Select notification type"
                                                />
                                            </div>
                                            <div className="notification-info" onClick={toggleOverlay}>
                                                <i className='icon-alert-info'></i>
                                            </div>
                                            <OverlayPanel ref={openGeneralInfo} dismissable className='notification-general-admin'>
                                                <div className="noti-info-area">
                                                    {/* Notification types (color-coded) */}
                                                </div>
                                            </OverlayPanel>
                                        </div>
                                    </OverlayPanel>

                                    <Button
                                        label={<i className="pi pi-bell"></i>}
                                        onClick={(e) => overlayPanel.current.toggle(e)}
                                        className="notification-btn"
                                    />
                                </div>
                            </div>

                            <div className='user-details p-text-right'>
                                <p className='user-name'>{userData?.Name}</p>
                                <p className='user-time'>{userData?.Email}</p>
                                <p className='user-time'>{userData?.UserType}</p>
                            </div>

                            <div className='profile-section' onClick={(e) => op.current.toggle(e)}>
                                <div className='p-d-flex p-ai-center cursor-pointer'>
                                    <div className='profile-img'>
                                        <img src={profilePath ? profilePath : userImage} width="150px" alt='userImage' onError={onError} />
                                    </div>
                                </div>
                            </div>

                            <div className='icon-box' onClick={(e) => op.current.toggle(e)}>
                                <i className='icon-chevron-down'></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Dialog
                header="Change Password"
                visible={isChangePassword}
                className='dialog-popup1'
                // onHide={(e) => onHide(e, 'changePassword')}
                draggable={false}

                position="top"
            >
                <div style={{ marginTop: '14px' }} className="floating-field mt-3">
                    <InputText placeholder=' ' className={`p-w-100 ${newPasswordErr ? "p-invalid block" : ""}`} value={oldPassword} onChange={(e) => handleCheck(e, 'oldPassword')} />
                    <label>Old Password</label>
                    <small className="p-error block">{oldPasswordErrText}</small>
                </div>

                <div style={{ marginTop: '14px' }} className="floating-field mt-3">
                    <InputText placeholder=' ' className={`p-w-100 ${newPasswordErr ? "p-invalid block" : ""}`} value={newPassword} onChange={(e) => handleCheck(e, 'newPassword')} />
                    <label>New Password</label>
                    <small className="p-error block">{newPasswordErrText}</small>
                </div>

                <div style={{ marginTop: '14px' }} className="floating-field mt-3">
                    <InputText placeholder=' ' className={`p-w-100 ${confirmPasswordErr ? "p-invalid block" : ""}`} value={confirmPassword} onChange={(e) => handleCheck(e, 'confirmPassword')} />
                    <label>Confirm Password</label>
                    <small className="p-error block">{confirmPasswordErrText}</small>
                </div>

                <Button label="Change" icon="pi pi-check" onClick={changePassword} className="dialog-btn1 mt-4" />
            </Dialog>
        </div>
    );
};

export default Header;

