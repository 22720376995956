//=============PATH=================//
import { apiBase } from './config';
const SERVER_PATH = apiBase;
export default class APIConstant {
  static path = {

    // Auth API

    login: `${SERVER_PATH}/authentication/loginUser`,
    logout: `${SERVER_PATH}/authentication/logoutUser`,
    GoogleMapApiKey: 'AIzaSyAww6TxPSzQbg74ljJahxvuLHmRfICHbf4',

    // Dashboard API
    getDahboardData: `${SERVER_PATH}/dashboard/getDahboardData`,
    getAdminDashboardUser: `${SERVER_PATH}/dashboard/getAdminDashboardUser`,
    getAdminDashboardUserList: `${SERVER_PATH}/dashboard/getAdminDashboardUserList`,

    //Sidebar API
    getUserMenu: `${SERVER_PATH}/menu/getMenuItems`,
    getUserType: `${SERVER_PATH}/useraccess/getUserType`,
    getMenuMaster: `${SERVER_PATH}/useraccess/getMenuMaster`,
    addUpdateUserType: `${SERVER_PATH}/useraccess/addUpdateUserType`,
    getUserTypeByID: `${SERVER_PATH}/useraccess/getUserTypeByID`,
    getUserWiseAccessList: `${SERVER_PATH}/userAccess/getUserAccessList`,


    // Add plan 
    getUserPlanList: `${SERVER_PATH}/userPlan/getUserPlanList`,

    //  user 
    AddUpdateUser: `${SERVER_PATH}/authentication/addUsers`,
    getUser: `${SERVER_PATH}/authentication/getUsers`,
    getUserById: `${SERVER_PATH}/authentication/getUserById`,
    updateUserStatus: `${SERVER_PATH}/authentication/updateUserStatus`,


    GetStringMap: `${SERVER_PATH}/stringMap/getStringMapList`,
    GetUserRole: `${SERVER_PATH}/userRole/GetUserRoleList`,

    //Class Master
    AddUpdateClassMaster: `${SERVER_PATH}/classMaster/addClassDetail`,
    getClassList: `${SERVER_PATH}/classMaster/getClassDetailList`,
    updateClassDetailStatus: `${SERVER_PATH}/classMaster/updateClassDetailStatus`,
    GetStudentIdCardData: `${SERVER_PATH}/classMaster/getStudentIdCardData`,

    //Section Master
    AddUpdateSectionMaster: `${SERVER_PATH}/sectionMaster/addSectionDetail`,
    getSectionList: `${SERVER_PATH}/sectionMaster/getSectionDetailList`,
    updateSectionDetailStatus: `${SERVER_PATH}/sectionMaster/updateSectionDetailStatus`,

    //Teacher Master
    AddPersonalDetail: `${SERVER_PATH}/personalDetail/addPersonalDetail`,
    GetPersonalDetailById: `${SERVER_PATH}/personalDetail/getPersonalDetailById`,
    AddQualificationDetail: `${SERVER_PATH}/qualificationDetail/addQualificationDetail`,
    GetQualificationDetailById: `${SERVER_PATH}/qualificationDetail/getQualificationDetailById`,

    // Student Master
    AddAdmissionDetail: `${SERVER_PATH}/admissionDetail/addAdmissionDetail`,
    GetAdmissionDetailById: `${SERVER_PATH}/admissionDetail/getAdmissionDetailById`,
    AddPreviousSchoolDetail: `${SERVER_PATH}/previousSchoolDetail/addPreviousSchoolDetail`,
    GetPreviousSchoolDetailById: `${SERVER_PATH}/previousSchoolDetail/getPreviousSchoolDetailById`,
    getStudentStatusList: `${SERVER_PATH}/studentStatusMaster/getStudentStatusList`,

    //House Master
    AddUpdateHouseMaster: `${SERVER_PATH}/houseMaster/addHouseDetail`,
    getHouseList: `${SERVER_PATH}/houseMaster/getHouseDetailList`,
    updateHouseDetailStatus: `${SERVER_PATH}/houseMaster/updateHouseDetailStatus`,

    //Class Configuration
    AddUpdateClassConfiguration: `${SERVER_PATH}/classConfiguration/addClassConfiguration`,
    getClassConfigurationList: `${SERVER_PATH}/classConfiguration/getClassConfigurationList`,
    getStudentDetailList: `${SERVER_PATH}/classConfiguration/getStudentDetailList`,
    getStudentDropDownList: `${SERVER_PATH}/classConfiguration/getStudentDropDownList`,
    updateClassConfigurationStatus: `${SERVER_PATH}/classConfiguration/updateClassConfigurationStatus`,
    getParentDetailList: `${SERVER_PATH}/classConfiguration/getParentDetailList`,
    getTeacherDetailList: `${SERVER_PATH}/classConfiguration/getTeacherDetailList`,
    getParentDropDownList: `${SERVER_PATH}/classConfiguration/getParentDropDownList`,
    updateParentStatus: `${SERVER_PATH}/classConfiguration/updateParentStatus`,
    updateStudentStatus: `${SERVER_PATH}/classConfiguration/updateStudentStatus`,
    updateTeacherStatus: `${SERVER_PATH}/classConfiguration/updateTeacherStatus`,
    getChildreenDropDownList: `${SERVER_PATH}/classConfiguration/getChildreenDropDownList`,

    //Board Master
    AddUpdateBoardMaster: `${SERVER_PATH}/boardMaster/addBoardDetail`,
    getBoardList: `${SERVER_PATH}/boardMaster/getBoardDetailList`,

    //Id Card Configuration
    AddUpdateIdCardConfig: `${SERVER_PATH}/idCardConfiguration/addIdCardConfiguration`,
    GetIdCardConfigurationList: `${SERVER_PATH}/idCardConfiguration/getIdCardConfigurationList`,
    UpdateIdCardConfigurationStatus: `${SERVER_PATH}/idCardConfiguration/updateIdCardConfigurationStatus`,

    //School Detail
    AddSchoolDetail: `${SERVER_PATH}/schoolDetail/addSchoolDetail`,
    GetSchoolDetailById: `${SERVER_PATH}/schoolDetail/getSchoolDetailById`,

    //Subject Master
    AddUpdateSubjectMaster: `${SERVER_PATH}/subjectMaster/addSubjectDetail`,
    getSubjectList: `${SERVER_PATH}/subjectMaster/getSubjectDetailList`,
    getSubjectByClassId: `${SERVER_PATH}/subjectMaster/getSubjectByClassId`,
    updateSubjectDetailStatus: `${SERVER_PATH}/subjectMaster/updateSubjectDetailStatus`,
    generateDynamicId: `${SERVER_PATH}/subjectMaster/generateDynamicId`,
    getLanguageList: `${SERVER_PATH}/languageMaster/getLanguageDetailList`,
    getBloodList: `${SERVER_PATH}/bloodMaster/getBloodDetailList`,
    getCasteList: `${SERVER_PATH}/casteMaster/getCasteDetailList`,
    getReligionList: `${SERVER_PATH}/religionMaster/getReligionDetailList`,

    addUpdateProfileConfiguration: `${SERVER_PATH}/profileConfiguration/addUpdateProfileConfiguration`,
    getProfileConfigurationList: `${SERVER_PATH}/profileConfiguration/getProfileConfigurationList`,

    //Academic Calander
    AddUpdateAcademicCalander : `${SERVER_PATH}/academicCalander/addAcademicCalanderConfiguration`,
    getAcademicCalanderTemplateList : `${SERVER_PATH}/academicCalander/getAcademicCalanderConfigurationList`,

    //Time Table
    AddUpdateTimeTableEntry : `${SERVER_PATH}/timeTable/addUpdateTimeTableEntry`,
    GetTimeTableByClass : `${SERVER_PATH}/timeTable/getTimeTableByClass`,
    AddUpdateTeacherTimeTableEntry : `${SERVER_PATH}/timeTable/addUpdateTeacherTimeTableEntry`,
    GetTimeTableByTeacher : `${SERVER_PATH}/timeTable/getTimeTableByTeacher`,

    // Shift Master
    AddUpdateShiftMaster: `${SERVER_PATH}/shiftMaster/addShiftDetail`,
    getShiftList: `${SERVER_PATH}/shiftMaster/getShiftDetailList`,
    updateShiftDetailStatus: `${SERVER_PATH}/shiftMaster/updateShiftDetailStatus`,

    // Leave Reason Master
    AddUpdateLeaveReasonMaster: `${SERVER_PATH}/leaveReasonMaster/addLeaveReasonDetail`,
    getLeaveReasonList: `${SERVER_PATH}/leaveReasonMaster/getLeaveReasonDetailList`,
    updateLeaveReasonDetailStatus: `${SERVER_PATH}/leaveReasonMaster/updateLeaveReasonDetailStatus`,

     // Fee Master
     AddUpdateFeeMaster: `${SERVER_PATH}/feeMaster/addFeeDetail`,
     getFeeList: `${SERVER_PATH}/feeMaster/getFeeDetailList`,
     updateFeeDetailStatus: `${SERVER_PATH}/feeMaster/updateFeeDetailStatus`,

     // Attendance
     GetAttendanceListByClass: `${SERVER_PATH}/attendance/getAttendanceList`,
     AddAttendanceDetail: `${SERVER_PATH}/attendance/addAttendanceDetail`,
     GetAttendanceClassList: `${SERVER_PATH}/attendance/getAttendanceClassList`,
     GetClassWiseAttendanceList: `${SERVER_PATH}/attendance/getClassWiseAttendanceList`,
     GetStudentWiseAttendanceList: `${SERVER_PATH}/attendance/getStudentWiseAttendanceList`,

     //Term Master
    AddUpdateTermMaster: `${SERVER_PATH}/termMaster/addTermDetail`,
    getTermList: `${SERVER_PATH}/termMaster/getTermDetailList`,
    updateTermDetailStatus: `${SERVER_PATH}/termMaster/updateTermDetailStatus`,

    //Stop Master
    AddUpdateStopMaster: `${SERVER_PATH}/stopMaster/addStopDetail`,
    getStopList: `${SERVER_PATH}/stopMaster/getStopDetailList`,
    updateStopDetailStatus: `${SERVER_PATH}/stopMaster/updateStopDetailStatus`,

  };
}
