
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import React, { useState, useEffect } from 'react';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import Toast from '../../Shared/Toast/Toast';
import { FloatLabel } from 'primereact/floatlabel';

const AddUpdateTermMasterDialog = (props) => {
    const [securityUserID, setSecurityUserID] = useState("");
    const [termName, setTermName] = useState('');
    const [termNameErrText, setTermNameErrText] = useState('');
    const [termNameErr, setTermNameErr] = useState(false);
    const [marks, setMarks] = useState('');
    const [marksErrText, setMarksErrText] = useState('');
    const [marksErr, setMarksErr] = useState(false);

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        if (props.ID) {
            setTermName(props?.masterData?.TermName);
            setMarks(props?.masterData?.Marks);
        }

    }, []);

    const onCancel = () => {
        props.onCancel();
        setTermName('');
        setMarks('');
    }

    const validate = () => {
        let formIsValid = true;
        if (CommonConfig.isEmpty(termName)) {
            formIsValid = false;
            setTermNameErr(true);
            setTermNameErrText("Term Name is required");
        } else {
            setTermNameErr(false);
            setTermNameErrText("");
        }
        if (CommonConfig.isEmpty(marks)) {
            formIsValid = false;
            setMarksErr(true);
            setMarksErrText("Marks is required");
        } else {
            setMarksErr(false);
            setMarksErrText("");
        }
        return formIsValid;
    };

    const AddTermMaster = async (e) => {
        if (validate(e)) {
            try {
                Loader.show();
                let data = {
                    id: props.ID,
                    TermName: termName,
                    Marks: marks,
                    CreatedBy: securityUserID,
                };

                await api
                    .post(APIConstant.path.AddUpdateTermMaster, data)
                    .then((response) => {
                        let res = response;
                        if (res.success) {
                            Toast.success({ message: res.message });
                            Loader.hide();
                            onCancel();
                        } else {
                            Toast.error({ message: res.message });
                            Loader.hide();
                            onCancel();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        Loader.hide();
                        onCancel();
                    });
            } catch (err) {
                console.log(err);
                Loader.hide();
                onCancel();
            }
        }
    };

    const handleChange = (e, type) => {
        const value = e.target.value;
        const alphanumericValue = value.replace(/[^a-zA-Z0-9\s!@#$%^&*]/g, '');
        const numericValue = value.replace(/[^0-9]/g, '');
        if (type === 'TermName') {
            setTermName(alphanumericValue);
        } else if (type === 'Marks') {
            setMarks(numericValue);
        }
    };

    return (
        <div>
            <div className='p-grid'>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <label htmlFor="clasName">Term Name</label>
                    <div className="">
                        <FloatLabel>
                            <InputText
                                className="p-mt-2 p-pl-2 p-w-100"
                                id="termName"
                                type="text"
                                maxLength={20}
                                placeholder="Term Name"
                                value={termName}
                                autoFocus={true}
                                onChange={(e) => handleChange(e, 'TermName')}
                            />
                        </FloatLabel>
                        <span className="error-msg" style={{ color: 'red' }}>
                            {termNameErr ? termNameErrText : null}
                        </span>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <label htmlFor="marks">Marks</label>
                    <div className="">
                        <FloatLabel>
                            <InputText
                                className="p-mt-2 p-pl-2 p-w-100"
                                id="marks"
                                maxLength={3}
                                placeholder="Marks"
                                value={marks}
                                autoFocus={true}
                                onChange={(e) => handleChange(e, 'Marks')}
                            />
                        </FloatLabel>
                        <span className="error-msg" style={{ color: 'red' }}>
                            {marksErr ? marksErrText : null}
                        </span>
                    </div>
                </div>

            </div>
            <div className="dialog-footer1 p-d-flex p-ai-center p-jc-end">
                <Button className='primary-btn1' onClick={(e) => onCancel()}> cancel </Button>
                <Button className='primary-btn' label="Submit" severity="secondary" raised onClick={(e) => AddTermMaster()} />
            </div>
        </div>
    );
};

export default AddUpdateTermMasterDialog;
