import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FilterMatchMode } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import AddParentMasterDialog from './AddParentMaster';
import { useNavigate } from 'react-router-dom';
import Toast from '../../Shared/Toast/Toast';
import DeleteConfirmation from '../../Dialogs/DeleteConfirmation';
import ParentCard from './ParentCard';

const ParentMaster = () => {
    const navigate = useNavigate();
    const [parentList, setParentList] = useState([]);
    const [masterData, setMasterData] = useState([]);
    const [classId, setParentId] = useState('');
    const [visible, setVisible] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [viewMode, setViewMode] = useState('card');
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        ParentId: { value: null, matchMode: FilterMatchMode.CONTAINS },
        ParentName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Gender: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Phone: { value: null, matchMode: FilterMatchMode.CONTAINS },
        EmailId: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [visibleStudentList, setVisibleStudentList] = useState(false);
    const [masterStudentList, setMasterStudentList] = useState([]);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [securityUserID, setSecurityUserID] = useState("");
    const [rows, setRows] = useState(10);
    const [first, setFirst] = useState(0);
    const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
    const [deleteId, setDeleteId] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                Loader.show();
                const loginData = CommonConfig.loginData();
                setSecurityUserID(loginData?._id);
                await GetParentList(loginData?._id);
            } catch (error) {
                Loader.hide();
                console.error('Error in useEffect:', error);
            } finally {
                Loader.hide();
            }
        };
        fetchData();
    }, []);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const onPageChange = (e) => {
        setFirst(e.first);
        setRows(e.rows);
    };

    const GetParentList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getParentDetailList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setParentList(res.data);
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const actionBodyTemplate = (rows) => {
        return (
            <React.Fragment>
                <div className='p-d-flex p-ai-center'>
                    <Button icon="icon-view tailer-icon" className="e-sction-btn" onClick={() => navigate('/viewEdit-parent-master', { state: { userId: rows?._id, isEdit: false } })} />
                    <Button icon="pi pi-pencil" className="e-sction-btn p-m-2" onClick={() => navigate('/viewEdit-parent-master', { state: { userId: rows?._id, isEdit: true } })} />
                    <Button icon="pi pi-trash" className="e-sction-btn" onClick={(e) => {
                        setIsDeleteConfirmation(true);
                        setDeleteId(rows._id);
                    }} />
                </div>
            </React.Fragment>
        );
    };

    const UpdateStatus = async () => {
        try {
            let data = {
                id: deleteId,
                Status: 'Inactive'
            }
            await api.post(APIConstant.path.updateParentStatus, data).then(async response => {
                let res = response;
                if (res.success) {
                    Loader.hide();
                    Toast.success({ message: 'Parent deleted successfully' });
                    GetParentList(securityUserID);
                }
                handleClose();
            }).catch(err => {
                Loader.hide();
                handleClose();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const renderFooter = () => (
        <div className="dialog-footer1 p-d-flex p-ai-center p-jc-end">
            <Button
                label="Cancel"
                className="primary-btn1"
                onClick={handleClose}
            />
            <Button
                label="Delete"
                className="primary-btn"
                onClick={(e) => UpdateStatus()}
            />
        </div>
    );

    const handleClose = () => {
        setIsDeleteConfirmation(false)
    };

    const onCancel = () => {
        setVisible(false);
        setParentId('');
        setIsEdit(false);
        setMasterData([]);
        GetParentList(securityUserID);
    }

    const actionBodyTemplateSrNo = (data, props) => {
        return props.rowIndex + 1;
    };

    const countBodyTemplateSrNo = (data, props) => {
        return <div onClick={() => {
            setVisibleStudentList(true);
            setMasterStudentList(data?.ChildrensList)
        }}>{data?.ChildrenCount}</div>
    };

    return (
        <div className='wayment-owner-dashboard e-weighment-dashoard'>
            <div className="heading-area p-d-flex p-ai-center p-jc-between">
                <div className="p-d-flex p-ai-baseline">
                    <i className="pi pi-users"></i>
                    <h2>Parent Master</h2>
                </div>
                <div className="p-d-flex p-ai-center p-jc-between">
                    <div className="searchbar-area e-searchbar-area">
                        <i className="pi pi-search" />
                        <InputText
                            type="search"
                            value={globalFilterValue}
                            onChange={onGlobalFilterChange}
                            placeholder="Global Search"
                        />
                    </div>
                    <Button icon="pi pi-plus" className="e-btn" onClick={() => setVisible(true)} />
                    <Button
                        icon={viewMode === 'card' ? 'pi pi-bars' : 'pi pi-th-large'}
                        className="e-btn p-ml-2"
                        onClick={() => setViewMode(viewMode === 'card' ? 'list' : 'card')}
                    />
                </div>
            </div>
            <hr className='p-m-0' />
            <div className={viewMode === 'card' ? 'teacher-grid' : 'teacher-list'}>
                {viewMode === 'card' ? (
                    parentList.filter(x => x?.Name?.toLowerCase()?.includes(globalFilterValue.toLowerCase()) || x?.Email?.includes(globalFilterValue) || x?.Phone?.includes(globalFilterValue)).map(parent => (
                        <ParentCard key={parent._id} parent={parent} />
                    ))
                ) : (
                    <DataTable
                        value={parentList}
                        paginator
                        rows={rows}
                        first={first}
                        filters={filters}
                        filterDisplay="menu"
                        globalFilterFields={['ParentId', 'Name', 'Gender', 'Phone', 'EmailId']}
                        responsiveLayout="scroll"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        rowsPerPageOptions={[5, 10, 20]}
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        onPage={onPageChange}
                        className='p-mt-3'
                    >
                        <Column field="id" body={actionBodyTemplateSrNo} header="Sr No." />
                        <Column
                            field="ParentId"
                            header="Parent ID"
                        />
                        <Column
                            field="Name"
                            header="Name"
                        />
                        <Column
                            field="Gender"
                            header="Gender"
                        />
                        <Column
                            body={countBodyTemplateSrNo}
                            header="Childrens"
                        />
                        <Column
                            field="Phone"
                            header="Contact No."
                        />
                        <Column
                            field="Email"
                            header="Email ID"
                        />
                        <Column body={actionBodyTemplate} header="Action" />
                    </DataTable>
                )}
            </div>
            <Dialog
                header={classId ? "Edit Parent Master" : "Add Parent Master"}
                className='dialog-popup'
                visible={visible}
                onHide={() => onCancel()}
                draggable={false}
                position="center"
                style={{width: '50vw'}}
            >
                <AddParentMasterDialog onCancel={onCancel} />

            </Dialog>

            <Dialog
                header="Childreen List"
                visible={visibleStudentList}
                onHide={() => {
                    setVisibleStudentList(false)
                    setMasterStudentList([]);
                }}
                draggable={false}
                position="top">

                <div className="p-col-12 p-card p-p-3">
                    <DataTable value={masterStudentList} paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rowsPerPageOptions={[10, 20, 50]}>
                        <Column body={actionBodyTemplateSrNo} header="Sr No." />
                        <Column field="Name" header="Childreen Name" />
                        <Column field="Email" header="Email" />
                        <Column field="Phone" header="Phone" />
                    </DataTable>
                </div>
            </Dialog>

            <Dialog
                visible={isDeleteConfirmation}
                onHide={handleClose}
                header={
                    <div className="p-d-flex p-jc-between p-ai-center">
                        <span>Delete Confirmation</span>
                    </div>
                }
                footer={renderFooter()}
                draggable={false}
                dismissableMask
                style={{ width: '450px' }}
            >
                <DeleteConfirmation />
            </Dialog>
        </div>
    );
};

export default ParentMaster;
