import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { Paginator } from 'primereact/paginator';
import CommonConfig from '../../../utils/constant';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import { Dialog } from 'primereact/dialog';
import Toast from '../../Shared/Toast/Toast';

const SectionChange = (props) => {
    const [securityUserID, setSecurityUserID] = useState("");
    const [visible, setVisible] = useState(false);
    const [studentsList, setStudentsList] = useState([]);
    const [selectedSection, setSelectedSection] = useState('');
    const [sectionList, setSectionList] = useState([]);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);

    const onPageChange = (e) => {
        setFirst(e.first);
        setRows(e.rows);
    };

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        setStudentsList(props?.studentList);
        GetSectionList(loginData?._id);
    }, []);

    const resetForm = () => {
        setSelectedSection();
    };

    const onCancel = () => {
        setVisible(false);
        resetForm();
    };

    const GetSectionList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getSectionList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setSectionList(res.data.map(x => ({
                        ...x,
                        label: x?.SectionName,
                        value: x._id
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const header = (
        <div className="p-grid p-nogutter">
            <div className="p-col">
                <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
            </div>
        </div>
    );
    const actionBodyTemplateSrNo = (data, props) => {
        return props.rowIndex + 1;
    };

    const nameBodyTemplate = (data, props) => {
        return (
            <div style={{margin: 'auto', color: 'green'}} >{data?.Name} </div> // onClick={() => setVisible(true)}
        )
    };

    return (
        <div className="p-fluid p-grid p-dir-col">
            <div className="p-col">
                <h2>{props?.ClassName} Student List</h2>
            </div>

            <div className="p-col-12 p-card p-p-3">
                <DataTable value={studentsList} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={rows} first={first} globalFilter={globalFilter}
                    onPage={onPageChange} rowsPerPageOptions={[10, 20, 50]} header={header} selectionMode="checkbox" selection={selectedStudents}
                    onSelectionChange={(e) => setSelectedStudents(e.value)}>
                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                    <Column body={actionBodyTemplateSrNo} header="Sr No." />
                    <Column field="StudentId" header="Student ID" />
                    <Column body={nameBodyTemplate} header="Student Name" />
                    <Column field="Gender" header="Gender" />
                    <Column field="RollNo" header="Roll No." />
                    <Column field='House' header="House" />
                </DataTable>
            </div>

            <div className="p-col-12 p-text-right">
                <Button label="Back" icon="pi pi-arrow-left" className="p-button-secondary p-mr-2" onClick={(e) => props.onCancel()} />
            </div>

            <Dialog
                header="Section"
                // className='dialog-popup'
                visible={visible}
                onHide={() => onCancel()}
                draggable={false}
                position="top"
            >
                <div style={{ padding: '20px', maxWidth: '400px', margin: 'auto', backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                
                    <div className="p-field">
                        <label htmlFor="sectionDropdown">Select Section</label>
                            <Dropdown
                                id="sectionDropdown"
                                value={selectedSection}
                                options={sectionList}
                                onChange={(e) => setSelectedSection(e.value)}
                                placeholder="Select Section"
                                style={{ width: '100%' }}
                            />
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                        <Button
                            label="Cancel"
                            className="p-button-secondary"
                            onClick={resetForm}
                            style={{ backgroundColor: '#f0f0f0', color: '#000' }}
                        />
                        <Button
                            label="Change"
                            className="p-button-primary"
                        />
                    </div>
                </div>

            </Dialog>
        </div>
    );
};

export default SectionChange;
