import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../../../assets/css/timeTable.css';
import Loader from "../../Shared/Loader/Loader";
import Toast from '../../Shared/Toast/Toast';
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { useNavigate } from 'react-router-dom';
import { Checkbox } from 'primereact/checkbox';

const EditClassTimeTable = (props) => {
    const [subjectList, setSubjectList] = useState([]);
    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const [periodsList, setPeriodsList] = useState([]);
    const [editTableData, setEditTableData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        setSubjectList(props?.location?.state?.subjectList);
        setEditTableData(props?.location?.state?.tableData);
        const periodData = props?.location?.state?.periodData.length && props?.location?.state?.periodData?.map((period) => {
            const [from, to] = period?.Time?.replace('Break - ', '')?.split(' - ')?.map((time) => {
                const [hours, minutes, ampm] = time?.match(/(\d+):(\d+)\s(AM|PM)/)?.slice(1);
                const date = new Date();
                date.setHours(ampm === 'PM' && hours !== '12' ? parseInt(hours, 10) + 12 : parseInt(hours, 10));
                date.setMinutes(parseInt(minutes, 10));
                return date;
            });

            return {
                Time: period?.Time,
                isEdit: false,
                isBreak: period?.isBreak,
                FromTime: from,
                ToTime: to,
            };
        });
        setPeriodsList(periodData);
    }, []);

    const handleDropdownChange = (e, rowData, period) => {
        const updatedData = editTableData.map(row => {
            if (row?.Day === rowData?.Day) {
                return { ...row, [period]: e.value };
            }
            return row;
        });

        const sortedData = updatedData.sort((a, b) => {
            return days.indexOf(a?.Day) - days.indexOf(b?.Day);
        });

        setEditTableData(sortedData);
    };

    const handleTeacherDropdownChange = (e, rowData, period) => {
        const updatedData = editTableData.map(row => {
            if (row?.Day === rowData?.Day) {
                return { ...row, [period]: e.value };
            }
            return row;
        });

        const sortedData = updatedData.sort((a, b) => {
            return days.indexOf(a?.Day) - days.indexOf(b?.Day);
        });

        setEditTableData(sortedData);
    };

    const saveTimeTable = async () => {
        try {
            const requestData = editTableData.map(row => ({
                id: row?.Id,
                // ClassId: props?.location?.state?.ClassId,
                Day: row?.Day,
                Periods: periodsList?.map((period, index) => ({
                    ClassId: props?.location?.state?.ClassId,
                    Time: period?.Time,
                    SubjectId: period?.Time?.includes('Break') ? 'Break' : row[`Period${index + 1}`],
                    TeacherId: period?.Time?.includes('Break') ? 'Break' : row[`Teacher${index + 1}`],
                }))
            }));
            const response = await api.post(APIConstant.path.AddUpdateTimeTableEntry, requestData);
            if (response.success) {
                Toast.success({ message: response.message });
                navigate('/time-table-configuration');
            } else {
                Toast.error({ message: "Failed to save timetable" });
            }
        } catch (error) {
            console.error("Error saving timetable:", error);
        }
    };

    const handleEditColumnName = (type, rowIndex, value) => {
        setPeriodsList((prevItems) =>
            prevItems.map((item, index) =>
                index === rowIndex ? { ...item, [type]: new Date(value) } : item
            )
        );
    };

    const handleDeleteColumn = (index) => {
        const updatedPeriodsList = periodsList?.filter((_, i) => i !== index);
        const updatedTableData = editTableData.map((row) => {
            const updatedRow = { ...row };
            delete updatedRow[`Period${index + 1}`];
            return updatedRow;
        });
        setPeriodsList(updatedPeriodsList);
        setEditTableData(updatedTableData);
    };

    const handleToggleEdit = (rowIndex) => {
        setPeriodsList((prevItems) =>
            prevItems.map((item, index) => {
                if (index === rowIndex) {
                    let formattedTime = item?.Time;
                    if (item?.isEdit && item?.FromTime && item?.ToTime) {
                        const formatTime = (time) => {
                            const date = new Date(time);
                            const hours = date.getHours();
                            const minutes = date.getMinutes();
                            const ampm = hours >= 12 ? 'PM' : 'AM';
                            const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
                            const formattedMinutes = minutes.toString().padStart(2, '0');
                            return `${formattedHours}:${formattedMinutes} ${ampm}`;
                        };
                        formattedTime = `${formatTime(item.FromTime)} - ${formatTime(item.ToTime)}`;
                    }

                    return {
                        ...item,
                        isEdit: !item?.isEdit,
                        Time: formattedTime,
                    };
                }
                return {
                    ...item,
                    isEdit: false,
                };
            })
        );
    };

    const handleToggleBreak = (rowIndex, value) => {
        setPeriodsList((prevItems) =>
            prevItems.map((item, index) => {
                if (index === rowIndex) {
                    return {
                        ...item,
                        isBreak: value,
                    };
                }
                return {
                    ...item,
                };
            })
        );
        const updatedTableData = editTableData.map((row, rowIdx) => {
            const updatedRow = { ...row };
            if (value) {
                updatedRow[`Period${rowIndex + 1}`] = 'Break';
            } else {
                const originalValue = props?.location?.state?.tableData[rowIdx]?.[`Period${rowIndex + 1}`];
                const originalTeacherValue = props?.location?.state?.tableData[rowIdx]?.[`Teacher${rowIndex + 1}`];
                updatedRow[`Period${rowIndex + 1}`] = (originalValue === 'Break' ? '' : originalValue) ?? updatedRow[`Period${rowIndex + 1}`];
                updatedRow[`Teacher${rowIndex + 1}`] = (originalTeacherValue === 'Break' ? '' : originalTeacherValue) ?? updatedRow[`Teacher${rowIndex + 1}`];
            }
            return updatedRow;
        });
        setEditTableData(updatedTableData);
    };

    const handleAddColumn = () => {
        const newColumn = {
            Time: `New Column ${periodsList.length + 1}`,
            isEdit: true,
            isBreak: false
        };
        setPeriodsList([...periodsList, newColumn]);
        const updatedTableData = editTableData.map((row) => ({
            ...row,
            [`Period${periodsList.length + 1}`]: '',
        }));
        setEditTableData(updatedTableData);
    };
    return (
        <div className="wayment-owner-dashboard e-weighment-dashoard">
            <div className="heading-area p-d-flex p-ai-center p-jc-between p-w-100">
                <div className="p-d-flex p-ai-baseline">
                    <i
                        className="pi pi-arrow-left"
                        style={{ cursor: 'pointer', marginRight: '1rem' }}
                        onClick={() => navigate('/time-table-configuration')}
                        title="Back"
                    ></i>
                    <h2>Edit Class Time Table</h2>
                </div>
                <div>
                    <Button
                        className="e-sction-btn p-my-2"
                        icon="pi pi-plus"
                        onClick={handleAddColumn}
                    />
                </div>
            </div>
            <hr className="p-m-0" />
            <div className='e-class-time-table'>
                <div>
                    <DataTable
                        value={editTableData}
                        responsiveLayout="scroll"
                        className="p-datatable-gridlines p-mt-2"
                    >
                        <Column field="Day" header="Days/Periods" />
                        {periodsList?.map((period, index) => (
                            <Column
                                key={index}
                                field={`Period${index + 1}`}
                                header={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {period?.isEdit ? (
                                            <>
                                                <Calendar
                                                    value={period?.FromTime}
                                                    onChange={(e) => handleEditColumnName('FromTime', index, e.value)}
                                                    hourFormat="12"
                                                    showTime
                                                    showSeconds={false}
                                                    timeOnly
                                                    className='time-table-input'
                                                />
                                                <Calendar
                                                    className='p-ml-2 time-table-input'
                                                    value={period?.ToTime}
                                                    onChange={(e) => handleEditColumnName('ToTime', index, e.target.value)}
                                                    hourFormat="12"
                                                    showTime
                                                    showSeconds={false}
                                                    timeOnly
                                                />
                                            </>
                                        ) : (
                                            <div>{period?.Time}</div>
                                        )}
                                        <Button
                                            icon={period?.isEdit ? "pi pi-check" : "pi pi-pencil"}
                                            className={period?.isEdit ? "e-btn p-ml-2" : "p-button-text p-button-danger"}
                                            onClick={() => handleToggleEdit(index)}
                                        />
                                        <Button
                                            icon="pi pi-trash"
                                            className="p-button-text e-btn p-ml-2 p-button-danger"
                                            onClick={() => handleDeleteColumn(index)}
                                        />
                                        <Checkbox
                                            className='p-ml-2'
                                            inputId="randomCheckbox"
                                            checked={period?.isBreak}
                                            onChange={(e) => handleToggleBreak(index, e.checked)}
                                        />
                                        <label htmlFor="randomCheckbox" style={{ marginLeft: '8px' }}>Break</label>
                                    </div>
                                }
                                body={(rowData) =>
                                    rowData[`Period${index + 1}`] === 'Break' ? (
                                        rowData[`Period${index + 1}`]
                                    ) : (
                                        <div className="dropdown-container">
                                            <Dropdown
                                                value={rowData[`Period${index + 1}`]}
                                                options={subjectList}
                                                onChange={(e) =>
                                                    handleDropdownChange(e, rowData, `Period${index + 1}`)
                                                }
                                                placeholder="Select Subject"
                                                style={{ width: '100%' }}
                                            />
                                            <Dropdown
                                                value={rowData[`Teacher${index + 1}`]}
                                                options={subjectList.filter(x => x?.value === rowData[`Period${index + 1}`] ?? '').length ? subjectList.filter(x => x?.value === rowData[`Period${index + 1}`] ?? '')[0]?.TeachersList : []}
                                                optionLabel='Name'
                                                optionValue='id'
                                                onChange={(e) => handleTeacherDropdownChange(e, rowData, `Teacher${index + 1}`)}
                                                placeholder="Select Teacher"
                                                className="dropdown"
                                            />
                                        </div>
                                    )
                                }
                            />
                        ))}
                    </DataTable>
                </div>

                <div className="p-d-flex p-ai-center p-jc-end">
                    <Button
                        className="p-m-0 primary-btn p-m-1 color-white"
                        label="Update"
                        severity="secondary"
                        raised
                        onClick={(e) => saveTimeTable()}
                    />
                </div>
            </div>
        </div>
    );
};

export default EditClassTimeTable;
