import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FilterMatchMode } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import GenerateIdCardDialog from './GenerateIdCard';
import { MultiSelect } from 'primereact/multiselect';
// import AddClassMasterDialog from './AddClassMaster';


const IdCardMaster = () => {

    const [classList, setClassList] = useState([]);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [studentsList, setStudentsList] = useState([]);
    const [masterData, setMasterData] = useState([]);
    const [classId, setClassId] = useState([]);
    const [visible, setVisible] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        board: { value: null, matchMode: FilterMatchMode.CONTAINS },
        medium: { value: null, matchMode: FilterMatchMode.CONTAINS },
        sections: { value: null, matchMode: FilterMatchMode.EQUALS },
        students: { value: null, matchMode: FilterMatchMode.EQUALS },
        totalCapacity: { value: null, matchMode: FilterMatchMode.EQUALS }
    });

    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [securityUserID, setSecurityUserID] = useState("");
    const [rows, setRows] = useState(10);
    const [first, setFirst] = useState(0);

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        GetClassList(loginData?._id);
    }, []);

    const onPageChange = (e) => {
        setFirst(e.first);
        setRows(e.rows);
    };

    const GetClassList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getClassList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setClassList(res.data.map(x => ({
                        ...x,
                        label: `${x?.ClassName}-${x?.SectionFullName.charAt(0)}-${x?.Medium}-${x?.Board}`,
                        value: x?._id
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const onCancel = () => {
        setVisible(false);
    }

    const onChangeClass = (e) => {
        const selectedClassIds = e.value;
        setClassId(selectedClassIds);

        let updatedStudentsList = [];
        selectedClassIds.forEach((classId) => {
            let classData = classList.find((x) => x.value === classId);
            if (classData) {
                if (classData.Users) {
                    updatedStudentsList = [...updatedStudentsList, ...classData.Users];
                }
            }
        });
        setStudentsList(updatedStudentsList);
        setSelectedStudents(updatedStudentsList);
    };

    const actionBodyTemplateSrNo = (data, props) => {
        return props.rowIndex + 1;
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    return (
        <div className='wayment-owner-dashboard e-weighment-dashoard'>
            <div className="heading-area p-d-flex p-ai-center p-jc-between">
                <div className="p-d-flex p-ai-baseline">
                    <i className="pi pi-briefcase"></i>
                    <h2>ID Card Generator</h2>
                </div>
                <div className="p-d-flex p-ai-center p-jc-between">
                    <div className="searchbar-area e-searchbar-area">
                        <i className="pi pi-search" />
                        <InputText
                            type="search"
                            value={globalFilterValue}
                            onChange={onGlobalFilterChange}
                            placeholder="Global Search"
                        />
                    </div>
                    <div className="searchbar-area">
                        <MultiSelect id="className"
                            value={classId} options={classList} onChange={(e) => onChangeClass(e)} placeholder="Please Select Class"
                            display="chip"
                            filter />
                    </div>
                    {classId.length ?
                        <Button icon="pi pi-plus" className="e-btn" onClick={() => setVisible(true)} />
                        : null}
                </div>
            </div>
            <hr className="p-m-0" />
            <DataTable
                value={studentsList}
                paginator
                rows={rows}
                first={first}
                filters={filters}
                filterDisplay="menu"
                globalFilterFields={['Name', 'Email', 'Phone']}
                responsiveLayout="scroll"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                rowsPerPageOptions={[5, 10, 20]}
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                onPage={onPageChange}
                className='p-mt-3 id-card-table'
                selectionMode="checkbox" selection={selectedStudents}
                onSelectionChange={(e) => setSelectedStudents(e.value)}
            >
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                <Column field="id" body={actionBodyTemplateSrNo} header="Sr No." />
                <Column
                    field="Name"
                    header="Student Name"
                />
                <Column
                    field="Email"
                    header="Email"
                />
                <Column
                    field="Phone"
                    header="Phone"
                />
            </DataTable>

            <Dialog
                header='Generate Id Card'
                className='dialog-popup'
                visible={visible}
                onHide={() => onCancel()}
                draggable={false}
                position="center"
            >
                <GenerateIdCardDialog masterData={masterData} isEdit={isEdit} ID={classId} onCancel={onCancel} studentsData={selectedStudents} />

            </Dialog>
        </div>
    );
};

export default IdCardMaster;
