import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import React, { useState, useEffect } from 'react';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import Toast from '../../Shared/Toast/Toast';
import { FloatLabel } from 'primereact/floatlabel';
import { Dropdown } from 'primereact/dropdown';

const AddTeacherMasterDialog = (props) => {
  const [securityUserID, setSecurityUserID] = useState("");
  const [teacherID, setTeacherID] = useState("");
  const [teacherName, setTeacherName] = useState('');
  const [teacherNameErrText, setTeacherNameErrText] = useState('');
  const [teacherNameErr, setTeacherNameErr] = useState(false);
  const [emailId, setEmailId] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [phoneNoErrText, setPhoneNoErrText] = useState('');
  const [phoneNoErr, setPhoneNoErr] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordErrText, setPasswordErrText] = useState('');
  const [passwordErr, setPasswordErr] = useState(false);
  const [isPasswordSeen, setIsPasswordSeen] = useState(false);
  const [accountTypeList, setAccountTypeList] = useState([]);
  const [userPlanList, setUserPlanList] = useState([]);
  const [userTypeList, setUserTypeList] = useState([]);
  const [emailErr, setEmailErr] = useState(false);
  const [emailErrText, setEmailErrText] = useState('');
  const [shiftList, setShiftList] = useState([]);
  const [selectedShiftId, setSelectedShiftId] = useState(null);
  const [selectedShiftIdErr, setSelectedShiftIdErr] = useState(false);
  const [selectedShiftIdErrText, setSelectedShiftIdErrText] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        Loader.show();
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        await getTeacherGeneratedId(loginData?._id);
        await GetShiftList(loginData?._id);
        await getAccountType();
        await GetUserRoleList();
        await GetUserPlanList(loginData?.AccountType, loginData?.UserType);
      } catch (error) {
        Loader.hide();
        console.error('Error in useEffect:', error);
      } finally {
        Loader.hide();
      }
    };
    fetchData();
  }, []);


  const GetShiftList = async (id) => {
    try {
      let data = {
        UserID: id,
      }
      await api.post(APIConstant.path.getShiftList, data).then(async response => {
        let res = response;
        if (res.success) {
          setShiftList(res.data.map(x => ({
            label: `${x?.ShiftName}(${formattedTime(x?.ShiftStart)} - ${formattedTime(x?.ShiftEnd)})`,
            value: x?._id
          })));
          Loader.hide();
        }
      }).catch(err => {
        Loader.hide();
        console.log(err);
      });
    } catch (err) { console.log(err); }
  }

  const formattedTime = (date) => {
    return new Date(date).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    })
};

  const getTeacherGeneratedId = async (id) => {
    try {
      let data = {
        type: "Teacher",
        UserID: id
      }
      await api.post(APIConstant.path.generateDynamicId, data).then(async response => {
        let res = response;
        if (res.success) {
          setTeacherID(res?.id);
          Loader.hide();
        }
      }).catch(err => {
        Loader.hide();
        console.log(err);
      });
    } catch (err) { console.log(err); }
  }

  const onCancel = () => {
    props.onCancel();
    setTeacherName('');
    setEmailId('');
    setTeacherID('');
    setPhoneNo('');
    setPassword('');
  }

  const validate = () => {
    let formIsValid = true;
    if (CommonConfig.isEmpty(teacherName)) {
      formIsValid = false;
      setTeacherNameErr(true);
      setTeacherNameErrText("Teacher Name is required");
    } else {
      setTeacherNameErr(false);
      setTeacherNameErrText("");
    }
    if (CommonConfig.isEmpty(emailId)) {
      formIsValid = false;
      setEmailErr(true);
      setEmailErrText("EmailId is required");
    } else {
      setEmailErr(false);
      setEmailErrText("");
    }
    if (CommonConfig.isEmpty(phoneNo)) {
      formIsValid = false;
      setPhoneNoErr(true);
      setPhoneNoErrText("PhoneNo is required");
    } else {
      setPhoneNoErr(false);
      setPhoneNoErrText("");
    }
    if (CommonConfig.isEmpty(password)) {
      formIsValid = false;
      setPasswordErr(true);
      setPasswordErrText("Password is required");
    } else {
      setPasswordErr(false);
      setPasswordErrText("");
    }
    if (CommonConfig.isEmpty(selectedShiftId)) {
      formIsValid = false;
      setSelectedShiftIdErr(true);
      setSelectedShiftIdErrText("Shift is required");
    } else {
      setSelectedShiftIdErr(false);
      setSelectedShiftIdErrText("");
    }
    return formIsValid;
  };

  const getAccountType = async () => {
    try {
      let data = {
        Type: "AccountType",
      };
      await api
        .post(APIConstant.path.GetStringMap, data)
        .then(async (response) => {
          let res = response;
          if (res.success) {
            setAccountTypeList(
              res.data.map((data) => ({ label: data.Name, value: data._id }))
            );
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log("err", err);
    }
  };

  const GetUserRoleList = async (value) => {
    let params = {
      AccountType: value,
    };
    try {
      await api
        .post(APIConstant.path.GetUserRole, params)
        .then(async (response) => {
          let res = response;
          if (res.success) {
            setUserTypeList(
              res.data.map((role) => ({
                label: role.RoleName,
                value: role._id,
              }))
            );
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const GetUserPlanList = async (AccountType, UserType) => {
    try {
      var params = {
        AccountType: AccountType,
        UserType: UserType,
      };
      await api
        .post(APIConstant.path.getUserPlanList, params)
        .then((res) => {
          if (res.success) {
            if (res.data) {
              setUserPlanList(
                res.data.map((plan) => ({
                  ...plan,
                  label: plan.PlanName,
                  value: plan._id,
                }))
              );
            }
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const AddTeacherMaster = async (e) => {
    if (validate(e)) {
      try {
        let data = {
          id: '',
          Name: teacherName,
          Email: emailId ?? '',
          Password: password,
          UserType: userTypeList.find((x) => x.label === 'Teacher')?.value,
          Phone: phoneNo ?? '',
          PlanId: userPlanList[0]?.value,
          isMailVerified: 1,
          isPhoneVerified: 1,
          AccountType: accountTypeList.find((x) => x.label === "New Member")?.value,
          CreatedBy: securityUserID
        };

        Loader.show();
        await api.post(APIConstant.path.AddUpdateUser, data).then(async response => {
          let res = response;
          if (res.success) {
            await AddPersonalDetail(res?.data?._id);
            Toast.success({ message: res.message });
            Loader.hide();
            onCancel();
          } else {
            Toast.error({ message: res.message });
            Loader.hide();
            onCancel();
          }
        })
          .catch((err) => {
            Loader.hide();
            onCancel();
          });
      } catch (err) {
        console.log(err);
        Loader.hide();
        onCancel();
      }
    }
  };

  const AddPersonalDetail = async (userId) => {
    try {
      let data = {
        id: '',
        UserId: userId,
        ProfileId: teacherID,
        ShiftId: selectedShiftId,
        CreatedBy: securityUserID
      };
      Loader.show();
      await api.post(APIConstant.path.AddPersonalDetail, data).then(async response => {
        let res = response;
        if (res.success) {
          Loader.hide();
        } else {
          Toast.error({ message: res.message });
          Loader.hide();
        }
      })
        .catch((err) => {
          Loader.hide();
        });
    } catch (err) {
      console.log(err);
      Loader.hide();
    }
  };

  const handleChange = (e, type) => {
    const value = e.target.value;
    const alphanumericValue = value.replace(/[^a-zA-Z0-9\s]/g, '');
    const numericValue = value.replace(/[^0-9]/g, '');
    if (type === 'TeacherName') {
      setTeacherName(alphanumericValue);
    } else if (type === 'MobileNo') {
      setPhoneNo(numericValue);
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    const maxLength = 50;
    if (value.length <= maxLength) {
      setEmailId(value);
    }
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(value)) {
      setEmailErr(true);
      setEmailErrText('Please enter a valid email address.');
    } else {
      setEmailErr(false);
      setEmailErrText('');
    }
  };

  return (
    <div>
      <div className='p-grid'>
        <div className="p-col-12 p-md-6 p-pt-2">
          <label htmlFor="clasName">Teacher ID </label>
          <InputText
            className="p-mt-2"
            id="teacherName"
            type="text"
            value={teacherID}
            disabled={true}
          />
        </div>
        <div className="p-col-12 p-md-6 p-pt-2">
          <div className="">
            <label htmlFor="teacherName">Teacher Name</label>
            <FloatLabel>
              <InputText
                className="p-mt-2"
                id="teacherName"
                type="text"
                placeholder="Teacher Name"
                value={teacherName}
                maxLength={40}
                autoFocus={true}
                onChange={(e) => handleChange(e, 'TeacherName')}
              />
            </FloatLabel>
            <span className="error-msg" style={{ color: 'red' }}>
              {teacherNameErr ? teacherNameErrText : null}
            </span>
          </div>
        </div>
        <div className="p-col-12 p-md-6 p-pt-2">
          <div className="">
            <label htmlFor="emailId">EmailId </label>
            <FloatLabel>
              <InputText
                className="p-mt-2"
                id="emailId"
                type="email"
                placeholder="EmailId"
                value={emailId}
                onChange={(e) => handleEmailChange(e)}
              />
            </FloatLabel>
            <span className="p-error block" style={{ color: 'red' }}>
              {emailErr ? emailErrText : null}
            </span>
          </div>
        </div>
        <div className="p-col-12 p-md-6 p-pt-2">
          <div className="">
            <label htmlFor="phoneNo">Phone No </label>
            <FloatLabel>
              <InputText
                className="p-mt-2"
                id="phoneNo"
                maxLength={10}
                placeholder="PhoneNo"
                value={phoneNo}
                onChange={(e) => handleChange(e, 'MobileNo')}
              />
            </FloatLabel>
            <span className="error-msg" style={{ color: 'red' }}>
              {phoneNoErr ? phoneNoErrText : null}
            </span>
          </div>
        </div>
        <div className="p-col-12 p-md-6 p-pt-2">
          <div className='custom-inputs password-field'>
            <label htmlFor="password">Password </label>
            <FloatLabel>
              <InputText
                id='password'
                type={isPasswordSeen ? 'text' : 'password'}
                placeholder='******'
                className='p-w-100 p-mt-2'
                maxLength={10}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <i
                className={
                  isPasswordSeen
                    ? 'icon-view tailer-icon'
                    : 'icon-view-off tailer-icon'
                }
                onClick={() =>
                  setIsPasswordSeen(!isPasswordSeen)
                }
              ></i>
            </FloatLabel>
            <span className="error-msg" style={{ color: 'red' }}>
              {passwordErr ? passwordErrText : null}
            </span>
          </div>
        </div>
        <div className="p-col-12 p-md-6 p-pt-2">
          <div className="">
            <label htmlFor="teacherName">Teacher Name</label>
            <Dropdown className='p-w-100' id="shiftId" value={selectedShiftId} options={shiftList} onChange={(e) => setSelectedShiftId(e.value)} placeholder="Select Shift" />
            <span className="error-msg" style={{ color: 'red' }}>
              {selectedShiftIdErr ? selectedShiftIdErrText : null}
            </span>
          </div>
        </div>
      </div>
      <div className='dialog-footer1 p-d-flex p-ai-center p-jc-end'>
        <Button className='p-m-0 primary-btn1 p-m-1' onClick={(e) => onCancel()}> Cancel </Button>
        <Button className='p-m-0 primary-btn p-m-1 color-white' label="Submit" severity="secondary" raised onClick={(e) => AddTeacherMaster()} />
      </div>
    </div>
  );
};

export default AddTeacherMasterDialog;
