import React, { useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import TeacherCard from './TeacherCard';
import '../../../assets/css/teacherMaster.css';
import { useNavigate } from 'react-router-dom';
import { FilterMatchMode } from 'primereact/api';
import AddTeacherMasterDialog from './AddTeacherMaster';
import { Dialog } from 'primereact/dialog';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Toast from '../../Shared/Toast/Toast';
import DeleteConfirmation from '../../Dialogs/DeleteConfirmation';

const TeacherMaster = () => {
    const navigate = useNavigate();
    const [securityUserID, setSecurityUserID] = useState("");
    const [sortOption, setSortOption] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [visible, setVisible] = useState(false);
    const [viewMode, setViewMode] = useState('card');
    const [rows, setRows] = useState(10);
    const [first, setFirst] = useState(0);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Phone: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Email: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [teachersList, setTeachersList] = useState([]);

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        GetTeachersList(loginData?._id);
    }, []);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const onCancel = () => {
        setVisible(false);
        GetTeachersList(securityUserID);
    }

    const onPageChange = (e) => {
        setFirst(e.first);
        setRows(e.rows);
    };

    const GetTeachersList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            Loader.show();
            await api.post(APIConstant.path.getTeacherDetailList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setTeachersList(res.data);
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) {
            Loader.hide();
            console.log(err);
        }
    }

    const actionBodyTemplate = (rows) => {
        return (
            <React.Fragment>
                <div className='p-d-flex p-ai-center'>
                    <Button icon="pi pi-pencil" className="e-sction-btn p-m-0" onClick={() => {
                        navigate('/viewEdit-teacher-master', { state: { userId: rows?._id, isEdit: true } })
                    }} />
                    <Button icon="pi pi-trash" className="e-sction-btn p-m-0 p-m-2" onClick={(e) => {
                        setIsDeleteConfirmation(true);
                        setDeleteId(rows._id);
                    }} />
                </div>
            </React.Fragment>
        );
    };

    const actionBodyTemplateSrNo = (data, props) => {
        return props.rowIndex + 1;
    };

    const renderFooter = () => (
        <div className="dialog-footer1 p-d-flex p-ai-center p-jc-end">
            <Button
                label="Cancel"
                className="primary-btn1"
                onClick={handleClose}
            />
            <Button
                label="Delete"
                className="primary-btn"
                onClick={(e) => UpdateStatus()}
            />
        </div>
    );

    const handleClose = () => {
        setIsDeleteConfirmation(false)
    };

    const UpdateStatus = async () => {
        try {
            let data = {
                id: deleteId,
                Status: 'Inactive'
            }
            await api.post(APIConstant.path.updateTeacherStatus, data).then(async response => {
                let res = response;
                if (res.success) {
                    Loader.hide();
                    Toast.success({ message: 'Teacher deleted successfully' });
                    GetTeachersList(securityUserID);
                }
                handleClose();
            }).catch(err => {
                Loader.hide();
                handleClose();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    return (
        <div className='wayment-owner-dashboard e-weighment-dashoard'>
            <div className="heading-area p-d-flex p-ai-center p-jc-between">
                <div className="p-d-flex p-ai-baseline">
                    <i className="pi pi-th-large"></i>
                    <h2>Teacher Master</h2>
                </div>
                <div className="p-d-flex p-ai-center p-jc-between">
                    <div className="searchbar-area">
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Global Search" />
                        </span>
                    </div>
                    <Button icon="pi pi-plus" className="e-btn" onClick={() => setVisible(true)} />
                    <Button
                        icon={viewMode === 'card' ? 'pi pi-bars' : 'pi pi-th-large'}
                        className="e-btn p-ml-2"
                        onClick={() => setViewMode(viewMode === 'card' ? 'list' : 'card')}
                    />
                </div>
            </div>
            <hr className="p-m-0" />
            <div className={viewMode === 'card' ? 'teacher-grid' : 'teacher-list'}>
                {viewMode === 'card' ? (
                    teachersList.filter(x => x?.Name?.toLowerCase()?.includes(globalFilterValue.toLowerCase()) || x?.Email?.includes(globalFilterValue) || x?.Phone?.includes(globalFilterValue)).map(teacher => (
                        <TeacherCard key={teacher._id} teacher={teacher} />
                    ))
                ) : (
                    <DataTable
                        value={teachersList}
                        paginator
                        rows={rows}
                        first={first}
                        filters={filters}
                        filterDisplay="menu"
                        globalFilterFields={['TeacherId', 'Name', 'Phone', 'Email', 'StudentListCount', 'TotalCapacity']}
                        responsiveLayout="scroll"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        rowsPerPageOptions={[5, 10, 20]}
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        onPage={onPageChange}
                        className='p-mt-3'
                    >
                        <Column field="id" body={actionBodyTemplateSrNo} header="Sr No." />
                        <Column
                            field="TeacherId"
                            header="Teacher Id"
                        />
                        <Column
                            field="Name"
                            header="Name"
                        />
                        <Column
                            field="Phone"
                            header="Phone"
                        />
                        <Column
                            field="Email"
                            header="Email"
                        />
                        <Column body={actionBodyTemplate} header="Action" />
                    </DataTable>
                )}
            </div>
            <Dialog
                header="Add Teacher Master"
                className='dialog-popup'
                visible={visible}
                onHide={() => onCancel()}
                draggable={false}

                position="top"
            >
                <AddTeacherMasterDialog onCancel={onCancel} />

            </Dialog>
            <Dialog
                visible={isDeleteConfirmation}
                onHide={handleClose}
                header={
                    <div className="p-d-flex p-jc-between p-ai-center">
                        <span>Delete Confirmation</span>
                    </div>
                }
                footer={renderFooter()}
                draggable={false}
                dismissableMask
                style={{ width: '450px' }}
            >
                <DeleteConfirmation />
            </Dialog>
        </div>
    );
}

export default TeacherMaster;
