
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import React, { useState, useEffect } from 'react';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import Toast from '../../Shared/Toast/Toast';
import { FloatLabel } from 'primereact/floatlabel';

const AddParentMasterDialog = (props) => {
    const [parentID, setParentID] = useState("");
    const [parentName, setParentName] = useState('');
    const [parentNameErrText, setParentNameErrText] = useState('');
    const [parentNameErr, setParentNameErr] = useState(false);
    const [securityUserID, setSecurityUserID] = useState("");
    const [selectedGender, setSelectedGender] = useState(null);
    const [selectedGenderErr, setSelectedGenderErr] = useState(false);
    const [selectedGenderErrText, setSelectedGenderErrText] = useState('');
    const genderOptions = [{ label: 'Male', value: 'male' }, { label: 'Female', value: 'female' }];
    const [childrens, setChildrens] = useState([]);
    const [childrensErr, setChildrensErr] = useState(false);
    const [childrensErrText, setChildrensErrText] = useState('');
    const [childrensList, setChildrensList] = useState([]);
    const [mobileNo, setMobileNo] = useState([]);
    const [mobileNoErr, setMobileNoErr] = useState(false);
    const [mobileNoErrText, setMobileNoErrText] = useState('');
    const [password, setPassword] = useState([]);
    const [passwordErr, setPasswordErr] = useState(false);
    const [passwordErrText, setPasswordErrText] = useState('');
    const [emailId, setEmailId] = useState('');
    const [emailIdErrText, setEmailIdErrText] = useState('');
    const [emailIdErr, setEmailIdErr] = useState(false);
    const [isPasswordSeen, setIsPasswordSeen] = useState(false);
    const [accountTypeList, setAccountTypeList] = useState([]);
    const [userPlanList, setUserPlanList] = useState([]);
    const [userTypeList, setUserTypeList] = useState([]);
    const [emailErr, setEmailErr] = useState(false);
    const [emailErrText, setEmailErrText] = useState('');

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        generateParentId(loginData?._id);
        getAccountType(loginData?._id);
        GetUserRoleList(loginData?._id);
        GetUserPlanList(loginData?.AccountType, loginData?.UserType);
        GetChildrensList(loginData?._id);
    }, []);

    const generateParentId = async (id) => {
        try {
            let data = {
                type: "Parent",
                UserID: id
            }
            await api.post(APIConstant.path.generateDynamicId, data).then(async response => {
                let res = response;
                if (res.success) {
                    setParentID(res?.id);
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const onCancel = () => {
        props.onCancel();
        setParentID('');
        setParentName('');
        setEmailId('');
        setMobileNo('');
    }

    const validate = () => {
        let formIsValid = true;
        if (CommonConfig.isEmpty(parentName)) {
            formIsValid = false;
            setParentNameErr(true);
            setParentNameErrText("Parent Name is required");
        } else {
            setParentNameErr(false);
            setParentNameErrText("");
        }
        if (CommonConfig.isEmpty(childrens)) {
            formIsValid = false;
            setChildrensErr(true);
            setChildrensErrText("Children is required");
        } else {
            setChildrensErr(false);
            setChildrensErrText("");
        }

        if (CommonConfig.isEmpty(mobileNo)) {
            formIsValid = false;
            setMobileNoErr(true);
            setMobileNoErrText("MobileNo is required");
        } else {
            setMobileNoErr(false);
            setMobileNoErrText("");
        }
        if (CommonConfig.isEmpty(password)) {
            formIsValid = false;
            setPasswordErr(true);
            setPasswordErrText("Password is required");
        } else {
            setPasswordErr(false);
            setPasswordErrText("");
        }
        return formIsValid;
    };

    const getAccountType = async (id) => {
        try {
            let data = {
                Type: "AccountType",
            };
            await api
                .post(APIConstant.path.GetStringMap, data)
                .then(async (response) => {
                    let res = response;
                    if (res.success) {
                        setAccountTypeList(
                            res.data.map((data) => ({ label: data.Name, value: data._id }))
                        );
                        Loader.hide();
                    }
                })
                .catch((err) => {
                    Loader.hide();
                    console.log(err);
                });
        } catch (err) {
            console.log("err", err);
        }
    };

    const GetUserRoleList = async (id) => {
        let params = {
            AccountType: id,
        };
        try {
            await api
                .post(APIConstant.path.GetUserRole, params)
                .then(async (response) => {
                    let res = response;
                    if (res.success) {
                        setUserTypeList(
                            res.data.map((role) => ({
                                label: role.RoleName,
                                value: role._id,
                            }))
                        );
                        Loader.hide();
                    }
                })
                .catch((err) => {
                    Loader.hide();
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    };

    const GetUserPlanList = async (AccountType, UserType) => {
        try {
            var params = {
                AccountType: AccountType,
                UserType: UserType,
            };
            await api
                .post(APIConstant.path.getUserPlanList, params)
                .then((res) => {
                    if (res.success) {
                        if (res.data) {
                            setUserPlanList(
                                res.data.map((plan) => ({
                                    ...plan,
                                    label: plan.PlanName,
                                    value: plan._id,
                                }))
                            );
                        }
                        Loader.hide();
                    }
                })
                .catch((err) => {
                    Loader.hide();
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    };

    const AddParentMaster = async (e) => {
        if (validate(e)) {
            try {
                let data = {
                    id: '',
                    Name: parentName,
                    Email: emailId,
                    Password: password,
                    UserType: userTypeList.find((x) => x.label === 'Parent')?.value,
                    Phone: mobileNo,
                    PlanId: userPlanList[0]?.value,
                    isMailVerified: 1,
                    isPhoneVerified: 1,
                    AccountType: accountTypeList.find((x) => x.label === "New Member")?.value,
                    CreatedBy: securityUserID
                };

                Loader.show();
                await api.post(APIConstant.path.AddUpdateUser, data).then(async response => {
                    let res = response;
                    if (res.success) {
                        await AddPersonalDetail(res?.data?._id);
                        Toast.success({ message: res.message });
                        Loader.hide();
                        onCancel();
                    } else {
                        Toast.error({ message: res.message });
                        Loader.hide();
                        onCancel();
                    }
                })
                    .catch((err) => {
                        Loader.hide();
                        onCancel();
                    });
            } catch (err) {
                console.log(err);
                Loader.hide();
                onCancel();
            }
        }
    };

    const AddPersonalDetail = async (userId) => {
        try {
            let data = {
                id: '',
                UserId: userId,
                ProfileId: parentID,
                Gender: selectedGender,
                TypeId: childrens.length ? (childrens.length > 1 ? childrens.join(',') : childrens[0]) : '',
                CreatedBy: securityUserID
            };
            Loader.show();
            await api.post(APIConstant.path.AddPersonalDetail, data).then(async response => {
                let res = response;
                if (res.success) {
                    Loader.hide();
                } else {
                    Toast.error({ message: res.message });
                    Loader.hide();
                }
            })
                .catch((err) => {
                    Loader.hide();
                });
        } catch (err) {
            console.log(err);
            Loader.hide();
        }
    };

    const GetChildrensList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getChildreenDropDownList, data).then(async response => {
                let res = response;
                if (res.success) {
                    const filteredStudentsList = res.data.map(student => ({
                        label: student?.Name,
                        value: student?._id,
                    }));

                    setChildrensList([...filteredStudentsList]);
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const handleChange = (e, type) => {
        const value = e.target.value;
        const alphanumericValue = value.replace(/[^a-zA-Z0-9\s!@#$%^&*]/g, '');
        const numericValue = value.replace(/[^0-9]/g, '');
        if (type === 'ParentName') {
            setParentName(alphanumericValue);
        } else if (type === 'MobileNo') {
            setMobileNo(numericValue);
        }
    };

    const handleEmailChange = (e) => {
        const value = e.target.value;
        const maxLength = 50;
        if (value.length <= maxLength) {
            setEmailId(value);
        }
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailPattern.test(value)) {
            setEmailErr(true);
            setEmailErrText('Please enter a valid email address.');
        } else {
            setEmailErr(false);
            setEmailErrText('');
        }
    };

    return (
        <div className="p-d-flex p-flex-column p-jc-between">
            <div className='p-grid'>
                <div className="p-col-12 p-md-4 p-pt-2">
                    <div className="">
                        <label htmlFor="parentId">Parent ID </label>
                        <InputText
                            // className="p-mt-2"
                            id="parentId"
                            type="text"
                            value={parentID}
                            disabled={true}
                        />
                    </div>
                </div>
                <div className="p-col-12 p-md-4 p-pt-2">
                    <div className="">
                        <label htmlFor="clasName">Parent Name </label>
                        <FloatLabel>
                            <InputText
                                // className="p-mt-2"
                                id="parentName"
                                type="text"
                                maxLength={40}
                                placeholder="Parent Name"
                                value={parentName}
                                autoFocus={true}
                                onChange={(e) => handleChange(e, 'ParentName')}
                            />
                        </FloatLabel>
                        <span className="error-msg" style={{ color: 'red' }}>
                            {parentNameErr ? parentNameErrText : null}
                        </span>
                    </div>
                </div>
                <div className="p-col-12 p-md-4 p-pt-2">
                    <label htmlFor="gender">Gender</label>
                    <Dropdown className='p-w-100' id="gender" value={selectedGender} options={genderOptions} onChange={(e) => setSelectedGender(e.value)} placeholder="Select Gender" />
                    <span className="error-msg" style={{ color: 'red' }}>
                        {selectedGenderErr ? selectedGenderErrText : null}
                    </span>
                </div>
                <div className="p-col-12 p-md-4 p-pt-2">
                    <div className="p-w-100">
                        <div className="custom-inputs p-w-100">
                            <label htmlFor="children">Children</label>
                            <MultiSelect
                                id="children"
                                value={childrens}
                                options={childrensList}
                                onChange={(e) => setChildrens(e.value)}
                                className='p-w-100'
                                placeholder="Select Childrens"
                                filter
                                display="chip"
                            />
                            <span className="error-msg" style={{ color: 'red' }}>
                                {childrensErr ? childrensErrText : null}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-4 p-pt-2">
                    <label htmlFor="totalCapacity">Mobile No.</label>
                    <div className="">
                        <FloatLabel>
                            <InputText
                                // className="p-mt-2"
                                id="mobileNo"
                                placeholder="Mobile No"
                                maxLength={10}
                                value={mobileNo}
                                onChange={(e) => handleChange(e, 'MobileNo')
                                }
                            />
                        </FloatLabel>
                        <span className="error-msg" style={{ color: 'red' }}>
                            {mobileNoErr ? mobileNoErrText : null}
                        </span>
                    </div>
                </div>
                <div className="p-col-12 p-md-4 p-pt-2">
                    <div className="">
                        <label htmlFor="emailId">Email Id</label>
                        <FloatLabel>
                            <InputText
                                // className="p-mt-2"
                                id="emailId"
                                type="email"
                                placeholder="Email Id"
                                value={emailId}
                                onChange={(e) => handleEmailChange(e)}
                            />
                        </FloatLabel>
                        <span className="p-error block" style={{ color: 'red' }}>
                            {emailErr ? emailErrText : null}
                        </span>
                    </div>
                </div>
                <div className="p-col-12 p-md-4 p-pt-2">
                    <div className='custom-inputs e-custom-inputs password-field'>
                        <label htmlFor="password">Password </label>
                        <FloatLabel>
                            <InputText
                                id='password'
                                type={isPasswordSeen ? 'text' : 'password'}
                                placeholder='******'
                                className='p-w-100 p-mt-2'
                                value={password}
                                maxLength={10}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <i
                                className={
                                    isPasswordSeen
                                        ? 'icon-view tailer-icon'
                                        : 'icon-view-off tailer-icon'
                                }
                                onClick={() =>
                                    setIsPasswordSeen(!isPasswordSeen)
                                }
                            ></i>
                        </FloatLabel>
                        <span className="error-msg" style={{ color: 'red' }}>
                            {passwordErr ? passwordErrText : null}
                        </span>
                    </div>
                </div>
            </div>
            <div className='dialog-footer1 p-d-flex p-ai-center p-jc-end'>
                <Button className='p-m-0 primary-btn1 p-m-1' onClick={(e) => onCancel()}> cancel </Button>
                <Button className='p-m-0 primary-btn' label="Submit" severity="secondary" raised onClick={(e) => AddParentMaster()} />
            </div>
        </div>
    );
};

export default AddParentMasterDialog;
