import React from 'react';
import { Button } from 'primereact/button';
import '../../../assets/css/teacherMaster.css';
import userImage from '../../../assets/icons/OBJECTS.svg';
import { useNavigate } from 'react-router-dom';
import { apiBase } from '../../../utils/config';
import CommonConfig from '../../../utils/constant';

const StudentCard = ({ student }) => {
    const navigate = useNavigate();
    const isNoLogin = CommonConfig.isEmpty(student?.Phone);

    return (
        <div className="teacher-card p-mt-4">
            <img src={student?.ProfilePhotoUrl ? `${apiBase}/UserProfilePic/${student?.ProfilePhotoUrl}` : userImage} alt='Profile' className="teacher-image" />
            <h4 className='p-m-0'>{student?.Name}</h4>
            <p className='p-m-0'>{student?.Status}</p>
            <p
                className={`p-m-0 ${isNoLogin ? 'no-login' : ''}`}
                style={isNoLogin ? { color: 'red', fontWeight: 'bold' } : {}}
            >
                {isNoLogin && 'No Login'}
            </p>

            <div className="p-d-flex p-ai-center p-jc-center">
                <Button label="View" className='e-btn p-m-0' onClick={() => navigate('/viewEdit-student-master', { state: { userId: student?._id, isEdit: false } })} />
                <Button label="Edit" className='e-sction-btn p-ml-2 p-mr-0' onClick={() => navigate('/viewEdit-student-master', { state: { userId: student?._id, isEdit: true } })} />
            </div>
        </div>
    );
}

export default StudentCard;
