import React, { useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { RadioButton } from 'primereact/radiobutton';
import { ColorPicker } from 'primereact/colorpicker';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { FloatLabel } from 'primereact/floatlabel';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primeicons/primeicons.css';
import '../../../assets/css/idCardTemplateConfig.css';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import Toast from '../../Shared/Toast/Toast';
import { apiBase } from '../../../utils/config';
import { Dialog } from 'primereact/dialog';
import DeleteConfirmation from '../../Dialogs/DeleteConfirmation';
import IdCard1 from '../../../assets/images/IdCard1.png';

const AddEditIdCardTemplate = (props) => {
    const [securityUserID, setSecurityUserID] = useState("");
    const [templateWidth, setTemplateWidth] = useState(null);
    const [templateHeight, setTemplateHeight] = useState(null);
    const [fontTheme, setFontTheme] = useState(null);
    const [orientation, setOrientation] = useState('vertical');
    const [titleName, setTitleName] = useState('');
    const [titleNameErr, setTitleNameErr] = useState(false);
    const [titleNameErrText, setTitleNameErrText] = useState('');
    const [applySettingTo, setApplySettingTo] = useState('front');
    const [nameBGColor, setNameBGColor] = useState('');
    const [isHouseWiseImage, setIsHouseWiseImage] = useState(false);
    const [nameColor, setNameColor] = useState('');
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [sigunatureImage, setSigunatureImage] = useState(null);
    const [authorityTitle, setAuthorityTitle] = useState(null);
    const [authorityTitleList, setAuthorityTitleList] = useState([
        { label: 'Principal', value: 'Principal' },
        { label: 'Class Tacher', value: 'Class Teacher' }
    ]);
    const [schoolImage, setSchoolImage] = useState(null);
    const [schoolPreviewImage, setSchoolPreviewImage] = useState(null);
    const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
    const [isNext, setIsNext] = useState(false);
    const fontThemes = [
        { label: 'Comic Sans', value: 'Comic Sans' },
        { label: 'Arial', value: 'Arial' },
        { label: 'Times New Roman', value: 'Times New Roman' }
    ];
    const [modelType, setModelType] = useState('');
    const [bodyoptionsList, setBodyoptionsList] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState('/static/media/IdCard1.ccfa70caf758e10f3b11.png');
    const [selectedBodyOptions, setSelectedBodyOptions] = useState([]);
    const [draggedIndex, setDraggedIndex] = useState(null);
    const [selectedProfileOptions, setSelectedProfileOptions] = useState('');
    const profileOptionsList = [
        { label: 'Round', value: 'round' },
        { label: 'Rectangle', value: 'rectangle' },
    ];
    const [previewProfilePath, setPreviewProfilePath] = useState('');
    const [previewSignPath, setPreviewSignPath] = useState('');
    const [editingIndex, setEditingIndex] = useState(null);
    const [newOptionValue, setNewOptionValue] = useState("");
    const [classAngle, setClassAngle] = useState('');

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        if (props?.configId) {
            setTitleName(props?.masterData?.TitleName);
            setTitleName(props?.masterData?.TitleName);
            setApplySettingTo(props?.masterData?.ApplyThisIn);
            setTemplateWidth(props?.masterData?.TemplateWidth);
            setTemplateHeight(props?.masterData?.TemplateHeight);
            setFontTheme(props?.masterData?.FontTheme);
            setClassAngle(props?.masterData?.ClassAngle);
            setOrientation(props?.masterData?.Orientation);
            setNameBGColor(props?.masterData?.NameBGColor ?? '');
            setIsHouseWiseImage(props?.masterData?.IsHouseWiseImage ?? false);
            setSelectedTemplate(props?.masterData?.TemplateName ?? selectedTemplate);
            let bodyList = [
                { label: 'Academic Year', value: 'AcademicYear' },
                { label: 'Class & Section', value: 'Class&Section' },
                { label: 'Student Name', value: 'StudentName' },
                { label: 'Father Name', value: 'FName' },
                { label: 'GRNo', value: 'GRNo' },
                { label: 'DOB', value: 'DOB' },
                { label: 'Father Phone No', value: 'FPhoneNo' },
                { label: 'Address', value: 'Address' },
                { label: 'Contact No.', value: 'contactNo' },
                { label: 'School Email', value: 'schoolEmail' }
            ];
            setSelectedBodyOptions(props?.masterData?.BodySection
                ? props?.masterData?.BodySection.map((item) => {
                    return item?.key;
                })
                : []);

            setBodyoptionsList(props?.masterData?.BodySection ? props?.masterData?.BodySection.map((item) => ({
                value: item?.key,
                label: item?.label,
            }))
                : bodyList)
            setSelectedProfileOptions(props?.masterData?.ProfileStyle);
            setNameColor(props?.masterData?.NameColor ?? '');
            setSelectedProfileOptions(props?.masterData?.ProfileStyle);
            setAuthorityTitle(props?.masterData?.AuthorityTitle);
            setBackgroundImage(props?.masterData?.BackGroundImage ? `${apiBase}/TemplateBackGround/${props?.masterData?.BackGroundImage}` : null);
            setSigunatureImage(props?.masterData?.SignImage ? `${apiBase}/TemplateSign/${props?.masterData?.SignImage}` : null);
            setSchoolImage(props?.masterData?.SignImage ? `${apiBase}/TemplateSign/${props?.masterData?.SchoolImage}` : null);

            // if (props?.masterData?.BodySection) {
            //     const bodyOrder = props?.masterData?.BodySection;
            //     const updatedList = bodyOrder
            //         .map((value) =>
            //             bodyoptionsList.find(
            //                 (item) => item?.value === value?.key
            //             )
            //         )
            //         .filter(Boolean);

            //     const remainingItems = bodyoptionsList.filter(
            //         (item) => !bodyOrder.some((value) => value?.key === item?.value)
            //     );

            //     const finalList = [...updatedList, ...remainingItems];
            //     setBodyoptionsList(finalList);
            // }

        }
    }, []);

    const validate = () => {
        let formIsValid = true;
        if (CommonConfig.isEmpty(titleName)) {
            formIsValid = false;
            setTitleNameErr(true);
            setTitleNameErrText("Title Name is required");
        } else {
            setTitleNameErr(false);
            setTitleNameErrText("");
        }
        return formIsValid;
    };

    const onCancel = () => {
        props.onCancel();
    }

    const AddUpdateTemplate = async (e) => {
        if (validate(e)) {
            try {
                let bodySectionData = [];
                if (selectedBodyOptions) {
                    bodySectionData = selectedBodyOptions.map((x, idx) => {
                        if (idx === 1) {
                            return {
                                label: 'StudentProfilePic',
                                key: 'StudentProfilePic'
                            }
                        }
                        return {
                            label: bodyoptionsList.filter(y => y?.value === x)[0]?.label,
                            key: x
                        }
                    })
                }
                const formData = new FormData();
                formData.append("id", props?.masterData?._id ?? '');
                formData.append('TemplateName', selectedTemplate);
                formData.append("TitleName", titleName);
                formData.append("ApplyThisIn", applySettingTo);
                formData.append("TemplateWidth", templateWidth);
                formData.append("TemplateHeight", templateHeight);
                formData.append("FontTheme", fontTheme);
                formData.append("Orientation", orientation);
                formData.append("NameBGColor", nameBGColor ?? '');
                formData.append("IsHouseWiseImage", isHouseWiseImage);
                formData.append("BodySection", JSON.stringify(bodySectionData));
                formData.append("NameColor", nameColor ?? '');
                formData.append("ProfileStyle", selectedProfileOptions);
                formData.append("ClassAngle", classAngle);
                formData.append("AuthorityTitle", authorityTitle);
                formData.append("CreatedBy", securityUserID);
                if (backgroundImage && backgroundImage.type) {
                    formData.append("BackGroundImage", backgroundImage);
                } else if (CommonConfig.isEmpty(backgroundImage)) {
                    formData.append("BackGroundImage", '');
                }
                if (sigunatureImage && sigunatureImage.type) {
                    formData.append("SignImage", sigunatureImage);
                } else if (CommonConfig.isEmpty(sigunatureImage)) {
                    formData.append("SignImage", '');
                }
                if (schoolImage && schoolImage.type) {
                    formData.append("SchoolImage", schoolImage);
                } else if (CommonConfig.isEmpty(schoolImage)) {
                    formData.append("SchoolImage", '');
                }
                Loader.show();
                await api
                    .post(APIConstant.path.AddUpdateIdCardConfig, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then((response) => {
                        let res = response;
                        if (res.success) {
                            Toast.success({ message: res.message });
                            Loader.hide();
                            onCancel();
                        } else {
                            Toast.error({ message: res.message });
                            Loader.hide();
                            onCancel();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        Toast.error({ message: err?.data?.message });
                        Loader.hide();
                        onCancel();
                    });
            } catch (err) {
                console.log(err);
                Toast.error({ message: err });
                Loader.hide();
                onCancel();
            }
        }
    };

    const handleBackGroundImage = (e) => {
        const selectedFile = e.files[0];
        if (selectedFile) {
            const validFormats = ['image/jpeg', 'image/png', 'image/jpg', 'image/svg+xml'];
            if (validFormats.includes(selectedFile.type)) {
                setBackgroundImage(selectedFile);
                const fileURL = URL.createObjectURL(selectedFile);
                setPreviewProfilePath(fileURL);
            } else {
                Toast.error({ message: 'Invalid file format. Please select a JPEG or PNG image.' });
            }
        }
    };

    const handleSigunatureImage = (e) => {
        const selectedFile = e.files[0];
        if (selectedFile) {
            const validFormats = ['image/jpeg', 'image/png', 'image/jpg', 'image/svg+xml'];
            if (validFormats.includes(selectedFile.type)) {
                setSigunatureImage(selectedFile);
                const fileURL = URL.createObjectURL(selectedFile);
                setPreviewSignPath(fileURL);
            } else {
                Toast.error({ message: 'Invalid file format. Please select a JPEG or PNG image.' });
            }
        }
    };

    const handleSchoolImage = (e) => {
        const selectedFile = e.files[0];
        if (selectedFile) {
            const validFormats = ['image/jpeg', 'image/png', 'image/jpg', 'image/svg+xml'];
            if (validFormats.includes(selectedFile.type)) {
                setSchoolImage(selectedFile);
                const fileURL = URL.createObjectURL(selectedFile);
                setSchoolPreviewImage(fileURL);
            } else {
                Toast.error({ message: 'Invalid file format. Please select a JPEG or PNG image.' });
            }
        }
    };

    const renderFooter = () => (
        <div className="dialog-footer1 p-d-flex p-ai-center p-jc-end">
            <Button
                label="Cancel"
                className="primary-btn1"
                onClick={handleClose}
            />
            <Button
                label="Delete"
                className="primary-btn"
                onClick={() => {
                    handleClose();
                    if (modelType === 'Sign') {
                        setSigunatureImage("")
                        setPreviewSignPath();
                    } else if (modelType === 'BackGround') {
                        setBackgroundImage("")
                        setPreviewProfilePath();
                    } else {
                        setSchoolImage("")
                        setSchoolPreviewImage();
                    }
                }}
            />
        </div>
    );

    const handleClose = () => {
        setIsDeleteConfirmation(false)
    };

    const handleDragStart = (index) => {
        setEditingIndex(null);
        setNewOptionValue("");
        setDraggedIndex(index);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const startEditing = (index, currentValue) => {
        setEditingIndex(index);
        setNewOptionValue(currentValue);
    };

    const saveEdit = (index) => {
        setBodyoptionsList((prev) =>
            prev.map((option, i) =>
                i === index ? { ...option, label: newOptionValue } : option
            )
        );
        setEditingIndex(null);
        setNewOptionValue("");
    };

    const cancelEdit = () => {
        setEditingIndex(null);
        setNewOptionValue("");
    };

    const handleDrop = (index) => {
        // if (draggedIndex === null || draggedIndex === index || index < 3) {
        //     Toast.error({ message: 'First 4 item can not be change' });
        //     return;
        // }
        const updatedList = [...bodyoptionsList];
        const draggedItem = updatedList[draggedIndex];
        updatedList.splice(draggedIndex, 1);
        updatedList.splice(index, 0, draggedItem);

        setBodyoptionsList(updatedList);
        const updatedSelectedBodyOptions = updatedList
            .filter((item) => selectedBodyOptions.includes(item.value))
            .map((item) => item.value);

        setSelectedBodyOptions(updatedSelectedBodyOptions);
        setDraggedIndex(null);
    };

    const handleCheckBodyboxChange = (e) => {
        const value = e.value;
        setSelectedBodyOptions((prevSelected) =>
            prevSelected.includes(value)
                ? prevSelected.filter((v) => v !== value)
                : [...prevSelected, value]
        );
    };

    const handleImageClick = (image) => {
        if (selectedTemplate === image) {
            setSelectedTemplate('');
        } else {
            setSelectedTemplate(image);
        }
    };

    return (
        <div className="template-config-container p-py-3 p-px-3">
            <div className="image-selection-container">
                {[IdCard1].map((image, index) => (
                    <div
                        key={index}
                        className={`image-item ${selectedTemplate === image ? 'selected' : ''}`}
                        onClick={() => handleImageClick(image)}
                        style={{
                            border: selectedTemplate === image ? '2px solid red' : '2px solid transparent',
                            cursor: 'pointer',
                            display: 'inline-block',
                            margin: '5px',
                        }}
                    >
                        <img
                            src={image}
                            alt={`IdCard${index + 1}`}
                            style={{ width: '200px', height: '300px' }}
                        />
                    </div>
                ))}
            </div>
            {!isNext ? (
                <div>
                    <h2 className='p-m-0 p-d-flex p-pb-1'>Title</h2>
                    <hr className='p-m-0' />
                    <div className="title-settings p-grid p-align-center">
                        <div className="p-col-6 p-md-6">
                            <label htmlFor="titleName">Title Name</label>
                            <FloatLabel>
                                <InputText
                                    className='p-mt-1 p-w-100'
                                    id="titleName"
                                    value={titleName}
                                    onChange={(e) => setTitleName(e.target.value)}
                                />
                            </FloatLabel>
                        </div>
                        <div className="p-col-6 p-md-6">
                            <label className="p-mb-2">Apply this setting to</label>
                            <div className="p-d-flex p-ai-center p-mt-2">
                                <div className="p-field-radiobutton p-mr-3">
                                    <RadioButton
                                        inputId="front"
                                        name="applySettingTo"
                                        value="front"
                                        checked={applySettingTo === 'front'}
                                        onChange={(e) => setApplySettingTo(e.value)}
                                    />
                                    <label htmlFor="front">Front</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="layout-settings">
                        <h2 className='p-m-0 p-d-flex p-pb-1'>Layout Settings</h2>
                        <hr className='p-m-0' />
                        <div className="p-grid p-fluid p-mt-2">
                            {/* <div className="p-col-6 p-md-6">
                                <label htmlFor="templateWidth">ID Template Width (cm)</label>
                                <FloatLabel>
                                    <InputNumber id="templateWidth" value={templateWidth} onValueChange={(e) => setTemplateWidth(e.value)} />
                                </FloatLabel>
                            </div> */}
                            <div className="p-col-6 p-md-6">
                                <label htmlFor="fontTheme">Font Theme</label>
                                <FloatLabel>
                                    <Dropdown id="fontTheme" className='p-pl-2 p-mt-0' value={fontTheme} options={fontThemes} onChange={(e) => setFontTheme(e.value)} placeholder="Select a font theme" />
                                </FloatLabel>
                            </div>
                        </div>
                    </div>
                    <div className="header-section p-grid">
                        <div className="p-col-12 p-md-6">
                            <div className="p-d-flex flex-column p-ai-center">
                                <img
                                    src={!CommonConfig.isEmpty(schoolPreviewImage) ? schoolPreviewImage : !CommonConfig.isEmpty(schoolImage) ? schoolImage : "https://via.placeholder.com/150"}
                                    alt="schoolImage"
                                    className="p-mb-3"
                                    style={{ width: '150px', height: '150px', borderRadius: '8px' }}
                                />
                                <div className='p-d-flex p-ai-center'>
                                    <FileUpload
                                        id="schoolImage"
                                        mode="basic"
                                        name="schoolImage"
                                        accept="image/*"
                                        maxFileSize={1000000}
                                        chooseLabel="Select School Logo"
                                        auto={false}
                                        onSelect={(e) => handleSchoolImage(e)}
                                    />
                                    {(!CommonConfig.isEmpty(schoolImage) || !CommonConfig.isEmpty(schoolPreviewImage)) && (
                                        <div
                                            className="e-btn p-py-3 p-px-3 p-ml-2"
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                setIsDeleteConfirmation(true)
                                                setModelType('');
                                            }}
                                        >
                                            <i className="pi pi-trash" style={{ fontSize: "16px", color: "red" }}></i>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <div className="p-d-flex flex-column p-ai-center">
                                <img
                                    src={!CommonConfig.isEmpty(previewProfilePath) ? previewProfilePath : !CommonConfig.isEmpty(backgroundImage) ? backgroundImage : "https://via.placeholder.com/150"}
                                    alt="backGroundImage"
                                    className="p-mb-3"
                                    style={{ width: '150px', height: '150px', borderRadius: '8px' }}
                                />
                                <div className='p-d-flex p-ai-center'>
                                    <FileUpload
                                        id="backgroundImage"
                                        mode="basic"
                                        name="backgroundImage"
                                        accept="image/*"
                                        maxFileSize={1000000}
                                        chooseLabel="Select Background Image"
                                        auto={false}
                                        onSelect={(e) => handleBackGroundImage(e)}
                                    />
                                    {(!CommonConfig.isEmpty(backgroundImage) || !CommonConfig.isEmpty(previewProfilePath)) && (
                                        <div
                                            className="e-btn p-ml-2"
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                setIsDeleteConfirmation(true)
                                                setModelType('BackGround');
                                            }}
                                        >
                                            <i className="pi pi-trash" style={{ fontSize: "16px", color: "red" }}></i>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6 p-mt-3">
                            <div className="p-field-checkbox p-mr-2 p-mb-0">
                                <Checkbox
                                    inputId='isHouseWiseImage'
                                    value={isHouseWiseImage}
                                    onChange={(e) => setIsHouseWiseImage(e.checked)}
                                    checked={isHouseWiseImage}
                                />
                                <label htmlFor='houseWiseImage'>House Wise BG Image</label>
                            </div>
                        </div>
                    </div>
                    <hr className='p-m-0' />
                    <Button label="Next" icon="pi pi-arrow-right" onClick={(e) => setIsNext(true)} />
                </div>) : (
                <>
                    <div className="header-section p-ai-center">
                        <h2 className="p-m-0 p-d-flex p-pb-2">Body Section</h2>
                        <hr className='p-m-0' />
                        <div className="p-col-12 p-md-6">
                            {bodyoptionsList.map((option, index) => (
                                <div
                                    key={option.value}
                                    className="p-field-checkbox p-d-flex p-ai-center p-jc-between"
                                    draggable
                                    onDragStart={() => handleDragStart(index)}
                                    onDragOver={handleDragOver}
                                    onDrop={() => handleDrop(index)}
                                    style={{ marginBottom: 0 }}
                                >
                                    <div className="p-d-flex p-ai-center">
                                        <Checkbox
                                            inputId={option.value}
                                            value={option.value}
                                            onChange={handleCheckBodyboxChange}
                                            checked={selectedBodyOptions.includes(option.value)}
                                            className="p-mr-2"
                                        />
                                        {editingIndex === index ? (
                                            <div className="p-d-flex p-ai-center">
                                                <InputText
                                                    value={newOptionValue ? newOptionValue : option.label}
                                                    onChange={(e) => setNewOptionValue(e.target.value)}
                                                    placeholder="Enter new value"
                                                    className="p-mr-2"
                                                />
                                                <Button
                                                    icon="pi pi-check"
                                                    onClick={() => saveEdit(index)}
                                                />
                                                <Button
                                                    icon="pi pi-times"
                                                    className="p-button-danger"
                                                    onClick={cancelEdit}
                                                />
                                            </div>
                                        ) : (
                                            <><label htmlFor={option.value} className="p-mr-2">{option.label}</label><Button
                                                icon="pi pi-pencil"
                                                className="p-button-text p-button-danger p-button-sm"
                                                onClick={() => startEditing(index, option.value)}
                                            /></>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="header-section p-ai-center">
                        <h2 className="p-m-0 p-d-flex p-pb-2">Style</h2>
                        <hr className='p-m-0' />
                        <div className='p-grid'>
                            <div className="p-col-12 p-md-9 checkbox-group p-d-flex p-flex-wrap">
                                {profileOptionsList.map((option, idx) => (
                                    <div key={option?.value} className="p-field-checkbox e-field-checkbox p-mr-2 p-mb-2">
                                        <Checkbox
                                            inputId={option?.value}
                                            value={option?.value}
                                            onChange={(e) => setSelectedProfileOptions(e.value)}
                                            checked={selectedProfileOptions === option?.value}
                                        />
                                        <label htmlFor={option?.value}>{option?.label}</label>
                                    </div>
                                ))}
                            </div>
                            <div className="p-col-6 p-md-6">
                                <label htmlFor="classAngle">Class Angle</label>
                                <FloatLabel>
                                    <InputNumber id="classAngle" value={classAngle} maxLength={3} onValueChange={(e) => setClassAngle(e.value)} />
                                </FloatLabel>
                            </div>
                            <div className="p-col-12 p-md-6 p-mt-3">
                                <div className="p-d-flex p-ai-baseline p-mt-1">
                                    <label htmlFor="nameColor" style={{ whiteSpace: 'nowrap' }}>Name Color</label>
                                    <ColorPicker
                                        value={nameColor}
                                        onChange={(e) => setNameColor(e.value)}
                                        className='p-w-100 p-ml-3'
                                    />
                                    <input
                                        type="text"
                                        value={nameColor ? `${nameColor.toUpperCase()}` : ''}
                                        readOnly={nameColor ? false : true}
                                        onChange={() => setNameColor('')}
                                        style={{
                                            width: '100%',
                                            border: '1px solid #ccc',
                                            borderRadius: '5px',
                                            marginTop: '0.5rem',
                                            padding: '0.5rem',
                                            textAlign: 'center'
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="p-col-12 p-md-6 p-mt-3">
                                <div className="p-d-flex p-ai-baseline p-mt-1">
                                    <label htmlFor="nameBGColor" style={{ whiteSpace: 'nowrap' }}>Name BG Color</label>
                                    <ColorPicker
                                        value={nameBGColor}
                                        onChange={(e) => setNameBGColor(e.value)}
                                        className='p-w-100 p-ml-3'
                                    />
                                    <input
                                        type="text"
                                        value={nameBGColor ? `${nameBGColor.toUpperCase()}` : ''}
                                        readOnly={nameBGColor ? false : true}
                                        onChange={() => setNameBGColor('')}
                                        style={{
                                            width: '100%',
                                            border: '1px solid #ccc',
                                            borderRadius: '5px',
                                            marginTop: '0.5rem',
                                            padding: '0.5rem',
                                            textAlign: 'center'
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="header-section  p-ai-center">
                        <h2 className="p-m-0 p-d-flex p-pb-2">Footer Section</h2>
                        <hr className='p-m-0' />
                        <div className='p-grid'>
                            <div className="p-col-12 p-md-6 p-mt-3">
                                <FloatLabel>
                                    <Dropdown id="authorityTitle" className='p-w-100' value={authorityTitle} options={authorityTitleList} onChange={(e) => setAuthorityTitle(e.value)} placeholder="Select Authority Title" />
                                    <label className='p-pl-2' htmlFor="authorityTitle">Authority Title</label>
                                </FloatLabel>
                            </div>
                        </div>
                        <div className='grid'>
                            <div className="p-col-12 p-md-12 p-md-4">
                                <div className="p-text-center">
                                    <img
                                        src={!CommonConfig.isEmpty(previewSignPath) ? previewSignPath : !CommonConfig.isEmpty(sigunatureImage) ? sigunatureImage : "https://via.placeholder.com/150"}
                                        alt="sigunatureImage"
                                        className="p-mb-3"
                                        style={{ width: '150px', height: '150px', borderRadius: '8px' }}
                                    />
                                    <div className='p-d-flex p-ai-center p-jc-center'>
                                        <FileUpload
                                            id="signImage"
                                            mode="basic"
                                            name="signImage"
                                            accept="image/*"
                                            maxFileSize={1000000}
                                            chooseLabel="Select Sigunature Image"
                                            auto={false}
                                            onSelect={(e) => handleSigunatureImage(e)}
                                        />
                                        {(!CommonConfig.isEmpty(sigunatureImage) || !CommonConfig.isEmpty(previewSignPath)) && (
                                            <div
                                                className="e-btn p-py-3 p-px-3 p-ml-2"
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                    setIsDeleteConfirmation(true)
                                                    setModelType('Sign');
                                                }}
                                            >
                                                <i className="pi pi-trash" style={{ fontSize: "16px", color: "red" }}></i>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='dialog-footer1 p-d-flex p-ai-center p-jc-center'>
                        <Button className='p-m-0 primary-btn1 p-m-1' label="Back" icon="pi pi-arrow-left" onClick={(e) => setIsNext(false)} />
                        <Button className='p-m-0 primary-btn' label="Save" onClick={(e) => AddUpdateTemplate()} />
                    </div>
                </>)
            }
            <Dialog
                visible={isDeleteConfirmation}
                onHide={handleClose}
                header={
                    <div className="p-d-flex p-jc-between p-ai-center">
                        <span>Delete Confirmation</span>
                    </div>
                }
                footer={renderFooter()}
                draggable={false}
                dismissableMask
                style={{ width: '450px' }}
            >
                <DeleteConfirmation />
            </Dialog>
        </div >
    );
};

export default AddEditIdCardTemplate;
