
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import React, { useState, useEffect } from 'react';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import Toast from '../../Shared/Toast/Toast';
import { FloatLabel } from 'primereact/floatlabel';
import { Dialog } from 'primereact/dialog';
import AddSectionMasterDialog from '../SectionMaster/AddSectionMaster';

const AddClassMasterDialog = (props) => {
    const [className, setClassName] = useState('');
    const [visible, setVisible] = useState(false);
    const [securityUserID, setSecurityUserID] = useState("");
    const [board, setBoard] = useState('');
    const [medium, setMedium] = useState('');
    const [sections, setSections] = useState('');
    const [classNameErrText, setClassNameErrText] = useState('');
    const [boardErrText, setBoardErrText] = useState('');
    const [mediumErrText, setMediumErrText] = useState('');
    const [classNameErr, setClassNameErr] = useState(false);
    const [boardErr, setBoardErr] = useState(false);
    const [mediumErr, setMediumErr] = useState(false);
    const [studentsCount, setStudentsCount] = useState([]);
    const [studentsCountErr, setStudentsCountErr] = useState(false);
    const [studentsCountErrText, setStudentsCountErrText] = useState('');
    const [totalCapacity, setTotalCapacity] = useState('');
    const [totalCapacityErr, setTotalCapacityErr] = useState(false);
    const [totalCapacityErrText, setTotalCapacityErrText] = useState('');
    const [sectionsList, setSectionList] = useState([]);
    const mediumList = [
        { label: 'English', value: 'English' },
        { label: 'Hindi', value: 'Hindi' }
    ];
    const [boardList, setBoardList] = useState([]);
    const [studentsList, setStudentsList] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                Loader.show();
                const loginData = CommonConfig.loginData();
                setSecurityUserID(loginData?._id);
                await GetBoardList(loginData?._id);
                await GetSectionDetailList(loginData?._id);
                await GetStudentsList(loginData?._id);
                if (props.ID) {
                    setClassName(props?.masterData?.ClassName);
                    setBoard(props?.masterData?.BoardName);
                    setMedium(props?.masterData?.Medium);
                    setSections(props?.masterData?.SectionName);
                    setStudentsCount(props?.masterData?.StudentsCount ? props?.masterData?.StudentsCount?.split(',') : []);
                    setTotalCapacity(props?.masterData?.TotalCapacity);
                    await GetAllStudentsList(loginData?._id);
                }
                Loader.hide();
            } catch (error) {
                Loader.hide();
                console.error('Error in useEffect:', error);
            } finally {
                Loader.hide();
            }
        };
        fetchData();
    }, []);

    const onCancel = () => {
        props.onCancel();
        setVisible(false);
        setClassName('');
        setBoard('');
        setMedium('');
        setStudentsCount([]);
        setTotalCapacity('');
        setSections([]);
        GetSectionDetailList(securityUserID);
    }

    const onSectionCancel = () => {
        setVisible(false);
        setSections([]);
        GetSectionDetailList(securityUserID);
    }

    const GetBoardList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getBoardList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setBoardList(res.data.map(x => ({
                        ...x,
                        label: x?.BoardName,
                        value: x?._id
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetStudentsList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getStudentDropDownList, data).then(async response => {
                let res = response;
                if (res.success) {
                    const filteredStudentsList = res.data.map(student => ({
                        ...student,
                        label: student?.Name,
                        value: student?._id,
                    }));

                    setStudentsList([...filteredStudentsList]);
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetAllStudentsList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getStudentDetailList, data).then(async response => {
                let res = response;
                if (res.success) {
                    const studentData = props?.masterData?.StudentsCount ? props?.masterData?.StudentsCount?.split(',') : [];
                    const filteredStudentsList = res.data
                        .filter(student => studentData.includes(String(student?._id)))
                        .map(student => ({
                            ...student,
                            label: student?.Name,
                            value: student?._id,
                        }));
                        
                    setStudentsList((prevList) => [...prevList, ...filteredStudentsList]);
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetSectionDetailList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getSectionList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setSectionList(res.data);
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const validate = () => {
        let formIsValid = true;
        if (CommonConfig.isEmpty(className)) {
            formIsValid = false;
            setClassNameErr(true);
            setClassNameErrText("Class Name is required");
        } else {
            setClassNameErr(false);
            setClassNameErrText("");
        }
        if (CommonConfig.isEmpty(board)) {
            formIsValid = false;
            setBoardErr(true);
            setBoardErrText("Board is required");
        } else {
            setBoardErr(false);
            setBoardErrText("");
        }

        if (CommonConfig.isEmpty(medium)) {
            formIsValid = false;
            setMediumErr(true);
            setMediumErrText("Medium is required");
        } else {
            setMediumErr(false);
            setMediumErrText("");
        }
        return formIsValid;
    };

    const AddClassMaster = async (e) => {
        if (validate(e)) {
            try {
                Loader.show();
                let data = {
                    id: props.ID,
                    ClassName: className,
                    BoardName: board,
                    Medium: medium,
                    SectionName: sections,
                    StudentsCount: studentsCount.length ? (studentsCount.length > 1 ? studentsCount.join(',') : studentsCount[0]) : '',
                    TotalCapacity: totalCapacity,
                    CreatedBy: securityUserID,
                };
                await api
                    .post(APIConstant.path.AddUpdateClassMaster, data)
                    .then((response) => {
                        let res = response;
                        if (res.success) {
                            Toast.success({ message: res.message });
                            Loader.hide();
                            onCancel();
                        } else {
                            Toast.error({ message: res.message });
                            Loader.hide();
                            onCancel();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        Loader.hide();
                        onCancel();
                    });
            } catch (err) {
                console.log(err);
                Loader.hide();
                onCancel();
            }
        }
    };

    const handleChange = (e, type) => {
        const value = e.target.value;
        const alphanumericValue = value.replace(/[^a-zA-Z0-9\s]/g, '');
        const numericValue = value.replace(/[^0-9]/g, '');
        if (type === 'ClassName') {
            setClassName(alphanumericValue);
        } else if(type === 'TotalCapacity'){
            setTotalCapacity(numericValue);
        }
    };

    const handleSectionChange = (e, type) => {
        setSections(e.value)
        let sectionData = sectionsList.filter(x => x?.value === e.value)[0];
        setTotalCapacity(sectionData?.TotalCapacity);
    }

    return <div>
        <div>
            <div className='p-grid'>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <label htmlFor="clasName">Class Name </label>
                    <div className="">
                        <FloatLabel>
                            <InputText
                                className="p-mt-2 p-w-100"
                                id="className"
                                type="text"
                                placeholder="Class Name"
                                maxLength={20}
                                value={className}
                                autoFocus={true}
                                onChange={(e) => handleChange(e, 'ClassName')}
                            />
                        </FloatLabel>
                        <span className="error-msg" style={{ color: 'red' }}>
                            {classNameErr ? classNameErrText : null}
                        </span>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <label htmlFor="Board" >Board</label>
                    <div className="p-mt-2 p-w-100">
                        <div className="custom-inputs p-w-100">
                            <Dropdown id="board" className='p-w-100' value={board} options={boardList} onChange={(e) => setBoard(e.value)} placeholder="Select Board" />
                        </div>
                        <span className="p-error block" style={{ color: 'red' }}>
                            {boardErr ? boardErrText : null}
                        </span>
                    </div>
                </div>
                <div className="p-col-12 p-md-12 p-pt-2">
                    <label htmlFor="Medium" className="mr-2">Medium</label>
                    <div className="">
                        <div className="custom-inputs">
                            <Dropdown
                                className="p-mt-2 p-w-100"
                                inputId="medium"
                                placeholder="Select Medium"
                                value={medium}
                                onChange={(e) => setMedium(e.value)}
                                options={mediumList}
                                optionLabel="label"
                                filter
                            />
                        </div>
                        <span className="p-error block" style={{ color: 'red' }}>
                            {mediumErr ? mediumErrText : null}
                        </span>
                    </div>
                </div>
                <div className="p-col-12  p-md-12 p-pt-2">
                    <label htmlFor="sections">Sections</label>
                    <div className="custom-inputs">
                        <div className="p-d-flex p-ai-center">
                            <Dropdown
                                id="sections"
                                value={sections}
                                options={sectionsList}
                                onChange={(e) => handleSectionChange(e)}
                                placeholder="Select Sections"
                                className="p-w-100"
                                display="chip"
                            />
                            <Button
                                className="e-btn white p-ml-2  "
                                icon="pi pi-plus text-white"
                                onClick={(e) => setVisible(true)}
                            />
                        </div>
                    </div>

                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <label htmlFor="studentsCount">Students Name: </label>
                    <div className="">
                        <MultiSelect
                            id="studentList"
                            value={studentsCount}
                            options={studentsList}
                            onChange={(e) => setStudentsCount(e.value)}
                            placeholder="Select Student"
                            className="p-mt-2 p-w-100"
                            filter
                            display="chip"
                        />
                        <span className="error-msg" style={{ color: 'red' }}>
                            {studentsCountErr ? studentsCountErrText : null}
                        </span>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <label htmlFor="totalCapacity">Total Capacity</label>
                    <div className="">
                        <FloatLabel>
                            <InputText
                                className="p-mt-2 p-w-100"
                                id="totalCapacity"
                                maxLength={5}
                                placeholder="Total Capacity"
                                value={totalCapacity}
                                onChange={(e) => handleChange(e, 'TotalCapacity')}
                            />
                        </FloatLabel>
                        <span className="error-msg" style={{ color: 'red' }}>
                            {totalCapacityErr ? totalCapacityErrText : null}
                        </span>
                    </div>
                </div>

            </div>
            <div className="dialog-footer1 p-d-flex p-ai-center p-jc-end">
                <Button className='primary-btn1' onClick={(e) => onCancel()}> Cancel </Button>
                <Button className='primary-btn color-white' label="Submit" severity="secondary" raised onClick={(e) => AddClassMaster()} />
            </div>
            <Dialog
                header="Add Section Master"
                className='dialog-popup'
                visible={visible}
                onHide={() => onSectionCancel()}
                draggable={false}
                position="top"
            >
                <AddSectionMasterDialog masterData={[]} isEdit={false} ID={''} onCancel={onSectionCancel} />
            </Dialog>
        </div>
    </div>
};

export default AddClassMasterDialog;
