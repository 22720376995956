import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FilterMatchMode } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import AddEditShiftMasterDialog from './AddEditShiftMaster';
import Toast from '../../Shared/Toast/Toast';
import DeleteConfirmation from '../../Dialogs/DeleteConfirmation';

const ShiftMaster = () => {
    const [shiftList, setShiftList] = useState([]);
    const [masterData, setMasterData] = useState([]);
    const [shiftId, setShiftId] = useState('');
    const [visible, setVisible] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        ShiftName: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [securityUserID, setSecurityUserID] = useState("");
    const [rows, setRows] = useState(10);
    const [first, setFirst] = useState(0);
    const [visibleStudentList, setVisibleStudentList] = useState(false);
    const [masterStudentList, setMasterStudentList] = useState([]);
    const [visibleClassList, setVisibleClassList] = useState(false);
    const [masterClassList, setMasterClassList] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                Loader.show();
                const loginData = CommonConfig.loginData();
                setSecurityUserID(loginData?._id);
                await GetShiftList(loginData?._id);
            } catch (error) {
                Loader.hide();
                console.error('Error in useEffect:', error);
            } finally {
                Loader.hide();
            }
        };
        fetchData();
    }, []);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['ShiftName'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const onPageChange = (e) => {
        setFirst(e.first);
        setRows(e.rows);
    };

    const GetShiftList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getShiftList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setShiftList(res.data);
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const UpdateShiftStatus = async () => {
        try {
            let data = {
                id: deleteId,
                Status: 'Inactive'
            }
            await api.post(APIConstant.path.updateShiftDetailStatus, data).then(async response => {
                let res = response;
                if (res.success) {
                    Loader.hide();
                    Toast.success({ message: 'Shift deleted successfully' });
                    GetShiftList(securityUserID);
                    setIsDeleteConfirmation(false);
                }
            }).catch(err => {
                Loader.hide();
                setIsDeleteConfirmation(false);
                console.log(err);
            });
        } catch (err) {
            console.log(err);
            setIsDeleteConfirmation(false);
        }
    }

    const actionBodyTemplate = (rows) => {
        return (
            <React.Fragment>
                <div className='p-d-flex p-ai-center'>
                    <Button icon="pi pi-pencil" className="e-sction-btn p-my-0 p-mr-2" onClick={() => {
                        setShiftId(rows._id)
                        setMasterData(rows);
                        setIsEdit(true)
                        setVisible(true)
                    }} />
                    <Button icon="pi pi-trash" className="e-sction-btn p-m-0" onClick={(e) => {
                        setIsDeleteConfirmation(true);
                        setDeleteId(rows._id);
                    }} />
                </div>
            </React.Fragment>
        );
    };

    const renderFooter = () => (
        <div className="dialog-footer1 p-d-flex p-ai-center p-jc-end">
            <Button
                label="Cancel"
                className="primary-btn1"
                onClick={handleClose}
            />
            <Button
                label="Delete"
                className="primary-btn"
                onClick={(e) => UpdateShiftStatus()}
            />
        </div>
    );

    const handleClose = () => {
        setIsDeleteConfirmation(false)
    };

    const onCancel = () => {
        setVisible(false);
        setShiftId('');
        setIsEdit(false);
        setMasterData([]);
        GetShiftList(securityUserID);
    }


    const actionBodyTemplateSrNo = (data, props) => {
        return props.rowIndex + 1;
    };

    const actionStartTemplate = (rowData, props) => {
        return formattedTime(rowData?.ShiftStart);
    };

    const actionEndTemplate = (rowData, props) => {
        return formattedTime(rowData?.ShiftEnd);
    };

    const formattedTime = (date) => {
        return new Date(date).toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        })
    };

    return (
        <div className='wayment-owner-dashboard e-weighment-dashoard'>
            <div className="heading-area p-d-flex p-ai-center p-jc-between">
                <div className="p-d-flex p-ai-baseline">
                    <i className="pi pi-server"></i>
                    <h2>Shift Master</h2>
                </div>
                <div className="p-d-flex p-ai-center p-jc-between">
                    <div className="searchbar-area e-searchbar-area">
                        <i className="pi pi-search" />
                        <InputText
                            type="search"
                            value={globalFilterValue}
                            onChange={onGlobalFilterChange}
                            placeholder="Global Search"
                        />
                    </div>
                    <Button icon="pi pi-plus" className="e-btn" onClick={() => setVisible(true)} />
                </div>
            </div>
            <hr className='p-m-0' />
            <DataTable
                value={shiftList}
                paginator
                rows={rows}
                first={first}
                filters={filters}
                filterDisplay="menu"
                globalFilterFields={['ShiftName']}
                responsiveLayout="scroll"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                rowsPerPageOptions={[5, 10, 20]}
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                onPage={onPageChange}
                className='p-mt-3'
            >
                <Column field="id" body={actionBodyTemplateSrNo} header="Sr No." />
                <Column
                    field="ShiftName"
                    header="Shift Name"
                />
                <Column
                    field="ShiftStartTime"
                    header="Shift Start"
                    body={actionStartTemplate}
                />
                <Column
                    field="ShiftEndTime"
                    header="Shift End"
                    body={actionEndTemplate}
                />

                <Column body={actionBodyTemplate} header="Action" />
            </DataTable>
            <Dialog
                header={shiftId ? "Edit Shift Master" : "Add Shift Master"}
                className='dialog-popup'
                visible={visible}
                onHide={() => onCancel()}
                draggable={false}
                position="center"
            >
                <AddEditShiftMasterDialog masterData={masterData} isEdit={isEdit} ID={shiftId} onCancel={onCancel} />

            </Dialog>
            <Dialog
                header="Teachers List"
                // className='dialog-popup'
                visible={visibleStudentList}
                onHide={() => {
                    setVisibleStudentList(false)
                    setMasterStudentList([]);
                }}
                draggable={false}
                position="top">

                <div className="p-col-12 p-card p-p-3">
                    <DataTable value={masterStudentList} paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rowsPerPageOptions={[10, 20, 50]}>
                        <Column body={actionBodyTemplateSrNo} header="Sr No." />
                        <Column field="Name" header="Teacher Name" />
                        <Column field="Email" header="Email" />
                        <Column field="Phone" header="Phone" />
                    </DataTable>
                </div>
            </Dialog>
            <Dialog
                header="Class List"
                visible={visibleClassList}
                onHide={() => {
                    setVisibleClassList(false)
                    setMasterClassList([]);
                }}
                draggable={false}
                position="top">

                <div className="p-col-12 p-card p-p-3">
                    <DataTable value={masterClassList} paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rowsPerPageOptions={[10, 20, 50]}>
                        <Column body={actionBodyTemplateSrNo} header="Sr No." />
                        <Column field="ClassName" header="Class Name" />
                        <Column field="Section" header="Section Name" />
                        <Column field="Board" header="Board Name" />
                        <Column field="Medium" header="Medium" />
                    </DataTable>
                </div>
            </Dialog>
            <Dialog
                visible={isDeleteConfirmation}
                onHide={handleClose}
                header={
                    <div className="p-d-flex p-jc-between p-ai-center">
                        <span>Delete Confirmation</span>
                    </div>
                }
                footer={renderFooter()}
                draggable={false}
                dismissableMask
                style={{ width: '450px' }}
            >
                <DeleteConfirmation />
            </Dialog>
        </div>
    );
};

export default ShiftMaster;
