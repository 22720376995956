import React, { useState, useEffect } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../../../assets/css/timeTable.css';
import Loader from "../../Shared/Loader/Loader";
import Toast from '../../Shared/Toast/Toast';
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import jsPDF from "jspdf";
import { InputText } from 'primereact/inputtext';
import { FloatLabel } from 'primereact/floatlabel';
import autoTable from "jspdf-autotable";
import { useNavigate } from 'react-router-dom';

const ClassTimeTableCard = (props) => {
    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const [periodsList, setPeriodsList] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [editTableData, setEditTableData] = useState([]);
    const [timetableData, setTimetableData] = useState([]);
    const [duration, setDuration] = useState('');
    const [interval, setInterval] = useState('');
    const navigate = useNavigate();
    const [subjectList, setSubjectList] = useState([]);

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        GetClassConfigurationDetail(props?.ClassId);
        GetSubjectList(loginData?._id);
        GetTimeTableData(props?.ClassId);
    }, []);

    const GetTimeTableData = async (classId) => {
        try {
            let data = {
                ClassId: classId,
            }
            const response = await api.post(APIConstant.path.GetTimeTableByClass, data);
            if (response?.success && response?.data.length > 0) {
                let periodData = response?.data[0]?.Periods?.map((x, idx) => {
                    return { Time: x?.Time, isBreak: x?.SubjectId === 'Break' ? true : false }
                });
                setPeriodsList(periodData);
                let timeTableData = response.data.map(row => {
                    if (row?.Duration) {
                        setDuration(row?.Duration);
                    }
                    if (row?.Interval) {
                        setInterval(row?.Interval);
                    }
                    const formattedRow = { Day: row?.Day, Id: row?.id };
                    row?.Periods?.forEach((period, index) => {
                        formattedRow[`Period${index + 1}`] = period?.SubjectId === 'Break' ? period?.SubjectId : period?.SubjectName;
                        formattedRow[`Teacher${index + 1}`] = period?.TeacherId === 'Break' ? period?.TeacherId : period?.TeacherName;
                    });
                    return formattedRow;
                });
                let timeTableEditData = response?.data?.map(row => {
                    const formattedRow = { Day: row?.Day, id: row?.id };
                    row?.Periods.forEach((period, index) => {
                        formattedRow[`Period${index + 1}`] = period?.SubjectId;
                        formattedRow[`Teacher${index + 1}`] = period?.TeacherId;
                    });
                    return formattedRow;
                });
                const sortedData = timeTableData.sort((a, b) => {
                    return days.indexOf(a?.Day) - days.indexOf(b?.Day);
                });
                const EditsortedData = timeTableEditData.sort((a, b) => {
                    return days.indexOf(a?.Day) - days.indexOf(b?.Day);
                });

                setTimetableData(sortedData);
                setEditTableData(EditsortedData);
            } else {
                setPeriodsList([]);
                setTimetableData([]);
                setEditTableData([]);
                Toast.error({ message: response?.message });
            }
        } catch (error) {
            console.error("Error fetching timetable:", error);
        }
    };

    const GetSubjectList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getSubjectList, data).then(async response => {
                let res = response;
                if (res.success) {
                    let subjectData = res?.data?.filter(y => y?.Classes.includes(props?.ClassId))?.map(x => ({
                        TeachersList: x?.TeachersList,
                        label: x?.SubjectName,
                        value: x._id
                    }));
                    setSubjectList(subjectData);
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetClassConfigurationDetail = async (id) => {
        try {
            let data = {
                ClassId: id,
            }
            Loader.show();
            await api.post(APIConstant.path.getClassConfigurationList, data).then(async response => {
                let res = response;
                if (res.success) {
                    let resData = res.data[0];
                    setDuration(resData?.Duration);
                    setInterval(resData?.Interval);
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) {
            Loader.hide();
            console.log(err);
        }
    }

    const toggleExpand = () => {
        setIsExpanded((prev) => !prev);
    };

    const printTimeTable = () => {
        try {
            Loader.show();
            const doc = new jsPDF();
            doc.setFontSize(16);
            doc.text(`Time Table for Class: ${props?.ClassName}`, 10, 10);

            const columns = [
                { header: "Days/Periods", dataKey: "Day" },
                ...periodsList.map((period, index) => ({
                    header: period?.Time,
                    dataKey: `Period${index + 1}`,
                })),
            ];

            const rows = timetableData.map((row) => {
                const formattedRow = { Day: row.Day };
                periodsList.forEach((_, index) => {
                    formattedRow[`Period${index + 1}`] = row[`Period${index + 1}`] === 'Break' ? row[`Teacher${index + 1}`] : `${row[`Period${index + 1}`]} - ${row[`Teacher${index + 1}`]}`;
                });
                return formattedRow;
            });

            autoTable(doc, {
                columns: columns,
                body: rows,
                startY: 20,
                theme: "grid",
            });
            Loader.hide();
            doc.save(`Class_Time_Table_${props?.ClassName || "Unknown"}.pdf`);
        } catch (error) {
            console.log('Error ');
            Loader.hide();
        }
    };

    return (
        <div className='time-table'>
            <Card className="card-actions p-mb-3" title={`Class : ${props?.ClassName}`}>
                <hr className='p-m-0' />
                {periodsList.length === 0
                ? <span>Please set class From and To time for this Class</span>
                : <>
                    <div className="card-header">
                        <div className="card-header-left">
                            <div className='p-d-flex p-ai-center'>
                                <h4 className='p-m-0 p-d-flex p-jc-start'>Duration minute : </h4>
                                <div>{duration}</div>
                            </div>
                            <div className='p-ml-3 p-d-flex p-ai-center'>
                                <h4 className='p-m-0 p-d-flex p-jc-start'>Interval minute : </h4>
                                <div> {interval}</div>
                            </div>
                        </div>
                        <div className="card-header-right">
                            <Button icon="pi pi-pencil" className="e-btn" onClick={() => navigate('/edit-class-time-table', { state: { tableData: editTableData, periodData: periodsList, subjectList: subjectList, ClassId: props?.ClassId, Duration: duration, Interval: interval } })} />
                            <Button icon="pi pi-print" className="e-btn p-mx-2" onClick={() => printTimeTable()} />
                            <Button
                                icon={`pi ${isExpanded ? 'pi-chevron-up' : 'pi-chevron-down'}`}
                                className="e-btn"
                                onClick={toggleExpand}
                            />
                        </div>
                    </div>
                    {isExpanded && (
                        <div className="card-content">
                            <DataTable value={timetableData} responsiveLayout="scroll" className="p-datatable-gridlines">
                                <Column field="Day" header="Days/Periods" />
                                {periodsList?.map((period, index) => (
                                    <Column
                                        key={index}
                                        field={`Period${index + 1}`}
                                        header={period?.Time}
                                        body={(rowData) =>
                                            rowData[`Period${index + 1}`] === 'Break' ? (
                                                rowData[`Period${index + 1}`]
                                            ) : (
                                                `${rowData[`Period${index + 1}`]} - ${rowData[`Teacher${index + 1}`]}`
                                            )
                                        }
                                    />
                                ))}
                            </DataTable>
                        </div>
                    )}
                </>}
            </Card>
        </div>
    );
};

export default ClassTimeTableCard;
