import React from 'react';
import { apiBase } from '../../utils/config';
import moment from 'moment';
import UserImage from '../../assets/icons/OBJECTS.svg';

const ExportIdCardPDF = React.forwardRef((props, ref) => {
    const { templateConfiguration, pdfData } = props;
    const fontFamily = templateConfiguration?.FontTheme;
    const pdfBlocks = [];

    const gradientStyle = (colorCode) => {
        const fallbackColor = '#649A80';
        const isValidHex = (code) => /^#?([0-9A-F]{3}|[0-9A-F]{6})$/i.test(code);
        const validColor = isValidHex(colorCode) ? (colorCode.startsWith('#') ? colorCode : `#${colorCode}`) : fallbackColor;
        return {
            background: `linear-gradient(to bottom, #E2E379, ${validColor}, ${validColor})`,
            borderRadius: '4px',
            padding: '10px',
            // width: templateConfiguration?.TemplateWidth ? (((templateConfiguration?.TemplateWidth) / 2.54) * 96) + 22 : '200px',
        };
    };
    
    pdfData?.map((x, idx) => {
        pdfBlocks.push(
            <div style={{ flex: '0 1 calc(20% - 8px)', marginLeft: '3px', marginTop: '15px', width: '180px', height: '378px',  justifyContent: 'center', ...gradientStyle(x?.HouseColor) }} ref={ref} key={idx} id={`id-card-${idx}`}>
                <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', width: 'px', justifyContent: 'center' }}>
                    <div style={{ position: 'relative', backgroundColor: 'transparent', borderRadius: '6px', opacity: 1, marginTop: '3px'}}>
                        <div style={{ width: '100%', maxHeight: '500px', height: '50px', backgroundColor: '#e2e379', display: 'flex', justifyContent: 'center', marginTop: '3px' }}>
                            <img
                                src={templateConfiguration?.SchoolImage ? `${apiBase}/TemplateSign/${templateConfiguration?.SchoolImage}` : x?.SchoolPhotoUrl ? `${apiBase}/UserProfilePic/${x?.SchoolPhotoUrl}` : UserImage}
                                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = UserImage;
                                }}
                            />
                        </div>
                        <div style={{ position: 'relative', backgroundColor: '#FFFFFF', borderRadius: '6px', opacity: '1', marginTop: '10px', }}>
                            <div style={{ width: '11rem', height: '11rem', backgroundColor: 'transparent', borderRadius: '50%', position: 'absolute', top: '25%', right: '0%', opacity: 0.06, }}>
                                <img style={{ width: '11rem', height: '11rem', borderRadius: '50%' }} src={templateConfiguration?.IsHouseWiseImage ? `${apiBase}/TemplateBackGround/${x?.HouseBgImage}` : templateConfiguration?.BackGroundImage ? `${apiBase}/TemplateBackGround/${templateConfiguration?.BackGroundImage}` : UserImage}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = UserImage;
                                    }} />
                            </div>
                            {templateConfiguration?.BodySection?.map((y, index) => {
                                return (
                                    <div style={{ marginTop: '5px' }}>
                                        {y?.key === 'AcademicYear' ?
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '0.3rem' }}>
                                                <p style={{ margin: '0px', fontSize: '0.75rem', fontStyle: 'normal', fontWeight: 700, color: '#074E69' }}>{x?.AcademicYear ?? '-'}</p>
                                            </div> :
                                            y?.key === 'StudentProfilePic' ?
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <div style={{
                                                        borderRadius: templateConfiguration?.ProfileStyle === 'round' ? '50%' : '6px', width: '70px', height: '70px'
                                                    }}
                                                    >
                                                        <img
                                                            src={x?.ProfilePhotoUrl ? `${apiBase}/UserProfilePic/${x?.ProfilePhotoUrl}` : UserImage}
                                                            style={{
                                                                borderRadius: templateConfiguration?.ProfileStyle === 'round' ? '50%' : '6px', width: '70px', height: '70px', backgroundColor: '#FFFFFF', margin: '0.3rem auto 0.3rem auto', display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                            }}
                                                            onError={(e) => {
                                                                e.target.onerror = null;
                                                                e.target.src = UserImage;
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                : y?.key === 'Class&Section' ?
                                                    <p style={{ margin: '0px', fontSize: '0.60rem', fontStyle: 'normal', fontWeight: 600, color: '#064D69', position: 'absolute', top: '18%', right: '6%', transform: `rotate(${templateConfiguration?.ClassAngle}deg)`, }}>{x?.ClassName}</p>
                                                    : y?.key === 'StudentName' ?
                                                        <div style={{
                                                            display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: 0.9, height: '40px', marginTop: '10px', backgroundColor: templateConfiguration?.NameBGColor ? `#${templateConfiguration?.NameBGColor}` : x?.HouseColor ? `#${x.HouseColor}` : '#064D69', textAlign: 'center'
                                                        }}
                                                        >
                                                            <p style={{
                                                                color: templateConfiguration?.NameColor ? `#${templateConfiguration?.NameColor}` : '#FFFFFF', margin: '0px', fontSize: '0.87rem'
                                                            }}>{x?.Name}</p>
                                                        </div>
                                                        : (y?.key === 'contactNo' || y?.key === 'schoolEmail') ?
                                                            null :
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'baseline',
                                                                    marginBottom: '5px',
                                                                }}
                                                                key={index}
                                                            >
                                                                <p style={{
                                                                    margin: 0,
                                                                    fontSize: '0.55rem',
                                                                    fontWeight: 700,
                                                                    color: '#064D69',
                                                                    textTransform: 'capitalize',
                                                                    maxWidth: '80px',
                                                                    flex: '1',
                                                                    textOverflow: 'ellipsis',
                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden',
                                                                    marginLeft: '10px',
                                                                }}
                                                                >
                                                                    {y?.label}
                                                                </p>
                                                                <p style={{
                                                                    margin: 0,
                                                                    fontSize: y?.key === 'Address' ? '0.55rem' : '0.57rem',
                                                                    fontWeight: 400,
                                                                    color: '#064D69',
                                                                    textTransform: 'capitalize',
                                                                    maxWidth: '150px',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    flex: '1',
                                                                    justifyContent: 'flex-start',
                                                                    textOverflow: 'ellipsis',
                                                                    whiteSpace: 'normal',
                                                                    overflow: 'hidden',
                                                                    wordWrap: 'break-word',
                                                                    wordBreak: 'break-word',
                                                                }}
                                                                >
                                                                    {y?.key === 'DOB' ? (x?.[y?.key] ? moment(x?.[y?.key]).format("DD-MM-YYYY") : '') : x?.[y?.key]}
                                                                </p>
                                                            </div>

                                        }
                                    </div>
                                )
                            }
                            )}

                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end', justifyContent: 'end', paddingBottom: '0.5rem' }}>
                                <img src={templateConfiguration?.SignImage ? `${apiBase}/TemplateSign/${templateConfiguration?.SignImage}` : UserImage} style={{ width: '30px', height: '30px', objectFit: 'contain', marginRight: '10px' }} onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = UserImage;
                                }} />
                                <p className="p-w-md-auto p-mr-2" style={{ margin: '0px', fontSize: '0.5rem', fontStyle: 'normal', fontWeight: 700, color: '#064D69', textTransform: 'capitalize', maxWidth: '70px', width: '70', }}>{templateConfiguration?.AuthorityTitle}</p>
                            </div>
                        </div>
                    </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <p style={{ fontSize: '0.50rem', fontStyle: 'normal', fontWeight: 500, color: '#FFFFFF', marginTop: '4px', marginBottom: '0px' }}>
                            {templateConfiguration?.BodySection?.filter(y => y?.key === 'contactNo').length ? x?.SchoolPhone : ''},{templateConfiguration?.BodySection?.filter(y => y?.key === 'schoolEmail').length ? x?.SchoolEmail : ''}
                            </p>
                        </div>
                </div>
            </div>
        )
    });
    return pdfBlocks;
});

export default ExportIdCardPDF;
