import React from 'react';
import { useNavigate } from 'react-router-dom';
import CommonConfig from '../utils/constant';

const PreventedRoute = (props) => {
     const navigate = useNavigate();
    const isLoggedIn = JSON.parse(localStorage.getItem('loginData'));
    let meta = props.meta;
    React.useEffect(() => {
        document.title = meta.title;
    }, [meta])

    if (isLoggedIn) {
        // user is not authenticated
        CommonConfig.getUserMenu(isLoggedIn, navigate);
    }
    return props.children;
};

export default PreventedRoute;