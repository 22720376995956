import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../../../assets/css/timeTable.css';
import Toast from '../../Shared/Toast/Toast';
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { useNavigate } from 'react-router-dom';
import { Checkbox } from 'primereact/checkbox';
import CommonConfig from '../../../utils/constant';

const EditTeacherTimeTable = (props) => {
    const [classList, setClassList] = useState([]);
    const [subjectList, setSubjectList] = useState([]);
    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const [periodsList, setPeriodsList] = useState([]);
    const [editTableData, setEditTableData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        setClassList(props?.location?.state?.classList);
        setSubjectList(props?.location?.state?.subjectList);
        setEditTableData(props?.location?.state?.tableData);
        setPeriodsList(
            props?.location?.state?.periodData.map((period) => {
                const [from, to] = period?.Time?.split(' - ').map((time) => {
                    const [hours, minutes, ampm] = time?.match(/(\d+):(\d+)\s(AM|PM)/)?.slice(1);
                    const date = new Date();
                    date.setHours(ampm === 'PM' && hours !== '12' ? parseInt(hours, 10) + 12 : parseInt(hours, 10));
                    date.setMinutes(parseInt(minutes, 10));
                    return date;
                });

                return {
                    Time: period?.Time,
                    isEdit: false,
                    isBreak: period?.isBreak,
                    FromTime: from,
                    ToTime: to,
                };
            })
        );
    }, []);

    const handleDropdownChange = (e, rowData, period) => {
        const updatedData = editTableData.map(row => {
            if (row?.Day === rowData?.Day) {
                return { ...row, [period]: e.value };
            }
            return row;
        });
        const sortedData = updatedData.sort((a, b) => {
            return days.indexOf(a?.Day) - days.indexOf(b?.Day);
        });
        setEditTableData(sortedData);
    };

    const handleClassDropdownChange = (e, rowData, period) => {
        const updatedData = editTableData.map(row => {
            if (row?.Day === rowData?.Day) {
                return { ...row, [period]: e.value };
            }
            return row;
        });

        const sortedData = updatedData.sort((a, b) => {
            return days.indexOf(a?.Day) - days.indexOf(b?.Day);
        });

        setEditTableData(sortedData);
    };

    const saveTimeTable = async () => {
        try {
            const requestData = editTableData.map(row => ({
                id: row?.Id,
                Day: row?.Day,
                Periods: periodsList.map((period, index) => ({
                    TeacherId: props?.location?.state?.TeacherId ?? '',
                    Time: period?.Time,
                    SubjectId: period?.Time?.includes('Free') ? '' : row[`Period${index + 1}`],
                    ClassId: period?.Time?.includes('Free') ? '' : row[`Class${index + 1}`],
                }))
            }));

            const response = await api.post(APIConstant.path.AddUpdateTeacherTimeTableEntry, requestData);
            if (response.success) {
                Toast.success({ message: response.message });
                navigate('/time-table-configuration');
            } else {
                Toast.error({ message: "Failed to save timetable" });
            }
        } catch (error) {
            console.error("Error saving timetable:", error);
        }
    };

    const handleEditColumnName = (type, rowIndex, value) => {
        setPeriodsList((prevItems) =>
            prevItems.map((item, index) =>
                index === rowIndex ? { ...item, [type]: new Date(value) } : item
            )
        );
    };

    const handleDeleteColumn = (index) => {
        const updatedPeriodsList = periodsList.filter((_, i) => i !== index);
        const updatedTableData = editTableData.map((row) => {
            const updatedRow = { ...row };
            delete updatedRow[`Period${index + 1}`];
            return updatedRow;
        });
        setPeriodsList(updatedPeriodsList);
        setEditTableData(updatedTableData);
    };

    const handleToggleEdit = (rowIndex) => {
        setPeriodsList((prevItems) =>
            prevItems.map((item, index) => {
                if (index === rowIndex) {
                    let formattedTime = item?.Time;
                    if (item?.isEdit && item?.FromTime && item?.ToTime) {
                        const formatTime = (time) => {
                            const date = new Date(time);
                            const hours = date.getHours();
                            const minutes = date.getMinutes();
                            const ampm = hours >= 12 ? 'PM' : 'AM';
                            const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
                            const formattedMinutes = minutes.toString().padStart(2, '0');
                            return `${formattedHours}:${formattedMinutes} ${ampm}`;
                        };
                        formattedTime = `${formatTime(item.FromTime)} - ${formatTime(item.ToTime)}`;
                    }

                    return {
                        ...item,
                        isEdit: !item?.isEdit,
                        Time: formattedTime,
                    };
                }
                return {
                    ...item,
                    isEdit: false,
                };
            })
        );
    };

    const handleToggleBreak = (rowIndex, value) => {
        setPeriodsList((prevItems) =>
            prevItems.map((item, index) => {
                if (index === rowIndex) {
                    return {
                        ...item,
                        isBreak: value,
                    };
                }
                return {
                    ...item,
                };
            })
        );
        const updatedTableData = editTableData.map((row, rowIdx) => {
            const updatedRow = { ...row };
            if (value) {
                updatedRow[`isFree${rowIndex + 1}`] = value;
                updatedRow[`Period${rowIndex + 1}`] = 'Free';
                updatedRow[`Class${rowIndex + 1}`] = 'Free';
            } else {
                const originalValue = props?.location?.state?.tableData[rowIdx]?.[`Period${rowIndex + 1}`];
                const originalTeacherValue = props?.location?.state?.tableData[rowIdx]?.[`Class${rowIndex + 1}`];
                const originalFreeValue = props?.location?.state?.tableData[rowIdx]?.[`isFree${rowIndex + 1}`];
                updatedRow[`Period${rowIndex + 1}`] = (originalValue === 'Free' ? '' : originalValue) ?? updatedRow[`Period${rowIndex + 1}`];
                updatedRow[`Class${rowIndex + 1}`] = (originalTeacherValue === 'Free' ? '' : originalTeacherValue) ?? updatedRow[`Class${rowIndex + 1}`];
                updatedRow[`isFree${rowIndex + 1}`] = originalFreeValue ?? updatedRow[`isFree${rowIndex + 1}`];
            }
            return updatedRow;
        });
        setEditTableData(updatedTableData);
    };

    const handleToggleFree = (rowIndex, value, dayValue) => {
        const updatedTableData = editTableData.map((row, rowIdx) => {
            if (row?.Day === dayValue) {
                const updatedRow = { ...row };
                if (value) {
                    updatedRow[`isFree${rowIndex + 1}`] = value;
                    updatedRow[`Period${rowIndex + 1}`] = 'Free';
                    updatedRow[`Class${rowIndex + 1}`] = 'Free';
                } else {
                    const originalValue = props?.location?.state?.tableData[rowIdx]?.[`Period${rowIndex + 1}`];
                    const originalTeacherValue = props?.location?.state?.tableData[rowIdx]?.[`Class${rowIndex + 1}`];
                    const originalFreeValue = props?.location?.state?.tableData[rowIdx]?.[`isFree${rowIndex + 1}`];
                    updatedRow[`Period${rowIndex + 1}`] = (originalValue === 'Free' ? '' : originalValue) ?? updatedRow[`Period${rowIndex + 1}`];
                    updatedRow[`Class${rowIndex + 1}`] = (originalTeacherValue === 'Free' ? '' : originalTeacherValue) ?? updatedRow[`Class${rowIndex + 1}`];
                    updatedRow[`isFree${rowIndex + 1}`] = originalFreeValue ?? updatedRow[`isFree${rowIndex + 1}`];
                }
                return updatedRow;
            }
            return row;
        });
        setEditTableData(updatedTableData);
    };

    const handleAddColumn = () => {
        const newColumn = {
            Time: `New Column ${periodsList.length + 1}`,
            isEdit: true,
            isBreak: false
        };
        setPeriodsList([...periodsList, newColumn]);
        const updatedTableData = editTableData.map((row) => ({
            ...row,
            [`Period${periodsList.length + 1}`]: '',
        }));
        setEditTableData(updatedTableData);
    };

    console.log('editTableData: ', editTableData);

    return (
        <div className="wayment-owner-dashboard e-weighment-dashoard">
            <div className="heading-area p-d-flex p-ai-center p-jc-between">
                <div className="p-d-flex p-ai-center">
                    <i
                        className="pi pi-arrow-left"
                        style={{ cursor: 'pointer', marginRight: '1rem' }}
                        onClick={() => navigate('/time-table-configuration')}
                        title="Back"
                    ></i>
                    <h2>Edit Teacher Time Table</h2>
                </div>
                <div>
                    <Button
                        className="p-m-0 e-btn"
                        icon="pi pi-plus"
                        onClick={handleAddColumn}
                    />
                </div>
            </div>
            <hr className="p-m-0" />
            <DataTable
                value={editTableData}
                responsiveLayout="scroll"
                className="p-datatable-gridlines p-mt-2 p-mx-1"
            >
                <Column field="Day" header="Days/Periods" />
                {periodsList?.map((period, index) => (
                    <Column
                        key={index}
                        field={`Period${index + 1}`}
                        header={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {period?.isEdit ? (
                                    <>
                                        <Calendar
                                            value={period?.FromTime}
                                            onChange={(e) => handleEditColumnName('FromTime', index, e.value)}
                                            hourFormat="12"
                                            showTime
                                            showSeconds={false}
                                            timeOnly
                                            className='time-table-input'
                                        />
                                        <Calendar
                                            value={period?.ToTime}
                                            onChange={(e) => handleEditColumnName('ToTime', index, e.target.value)}
                                            hourFormat="12"
                                            showTime
                                            showSeconds={false}
                                            timeOnly
                                            className='p-ml-2 time-table-input'
                                        />
                                    </>
                                ) : (
                                    <div>{period?.Time}</div>
                                )}
                                <Button
                                    icon={period?.isEdit ? "pi pi-check" : "pi pi-pencil"}
                                    className={period?.isEdit ? "p-button-text" : "p-button-text p-button-danger"}
                                    onClick={() => handleToggleEdit(index)}
                                />
                                <Button
                                    icon="pi pi-trash"
                                    className="p-button-text p-button-danger"
                                    onClick={() => handleDeleteColumn(index)}
                                />
                                <Checkbox
                                    inputId="randomCheckbox"
                                    checked={period?.isBreak}
                                    onChange={(e) => handleToggleBreak(index, e.checked)}
                                />
                                <label htmlFor="randomCheckbox" style={{ marginLeft: '8px' }}>Free All</label>
                            </div>
                        }
                        body={(rowData) => {
                            return (
                                <>
                                    <Checkbox
                                        inputId="randomCheckbox"
                                        checked={rowData[`isFree${index + 1}`]}
                                        onChange={(e) => handleToggleFree(index, e.checked, rowData?.Day)}
                                    />
                                    <label htmlFor="randomCheckbox" style={{ marginLeft: '8px' }}>
                                        Free
                                    </label>

                                    {!rowData[`isFree${index + 1}`] && (
                                        <div className="dropdown-container">
                                            <Dropdown
                                                value={rowData[`Class${index + 1}`]}
                                                options={classList}
                                                onChange={(e) => handleClassDropdownChange(e, rowData, `Class${index + 1}`)}
                                                placeholder="Select Class"
                                                className="dropdown"
                                            />
                                            <Dropdown
                                                value={rowData[`Period${index + 1}`]}
                                                options={subjectList}
                                                onChange={(e) => handleDropdownChange(e, rowData, `Period${index + 1}`)}
                                                placeholder="Select Subject"
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                    )}
                                </>
                            );
                        }}

                    />
                ))}
            </DataTable>
            <div className="dialog-footer1 p-d-flex p-ai-center p-jc-end">
                <Button
                    className="p-m-0 primary-btn p-m-1 color-white"
                    label="Update"
                    severity="secondary"
                    raised
                    onClick={(e) => saveTimeTable()}
                />
            </div>
        </div>
    );
};

export default EditTeacherTimeTable;
