import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import userImage from '../../../assets/icons/OBJECTS.svg';
import { TabView, TabPanel } from 'primereact/tabview';
import '../../../assets/css/teacherMasterDetail.css';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import { InputTextarea } from 'primereact/inputtextarea';
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import { FileUpload } from 'primereact/fileupload';
import Toast from '../../Shared/Toast/Toast';
import { Navigate, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { apiBase } from '../../../utils/config';
import { Dialog } from 'primereact/dialog';
import DeleteConfirmation from '../../Dialogs/DeleteConfirmation';

const ViewEditTeacherDetails = (props) => {
    const navigate = useNavigate();
    const [securityUserID, setSecurityUserID] = useState("");
    const [personalDetailId, setPersonalDetailId] = useState('');
    const [fullName, setFullName] = useState("");
    const [teacherId, setTeacherId] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [alternateMobileNumber, setAlternateMobileNumber] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [selectedGender, setSelectedGender] = useState(null);
    const [selectedBloodGroup, setSelectedBloodGroup] = useState(null);
    const [selectedNationality, setSelectedNationality] = useState(null);
    const [selectedReligion, setSelectedReligion] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [languages, setLanguages] = useState([]);
    const [aadharNo, setAadharNo] = useState('');
    const [aadharOTP, setAadharOTP] = useState('');
    const [isAadharVerify, setIsAadharVerify] = useState(false);
    const [dob, setDob] = useState(null);
    const [doj, setDoj] = useState(null);
    const [age, setAge] = useState('');
    const [pinCode, setPinCode] = useState('');
    const [currentAddress, setCurrentAddress] = useState('');
    const [permanentAddress, setPermanentAddress] = useState('');
    const [oldPermanentAddress, setOldPermanentAddress] = useState('');
    const [lastOrganzation, setLastOrganzation] = useState('');
    const [experience, setExperience] = useState('');
    const [position, setPosition] = useState('');
    const genderOptions = [{ label: 'Male', value: 'male' }, { label: 'Female', value: 'female' }];
    const nationalityOptions = [{ label: 'Indian', value: 'indian' }, { label: 'Other', value: 'other' }];
    const [qualifications, setQualifications] = useState([]);
    const [emailErr, setEmailErr] = useState(false);
    const [emailErrText, setEmailErrText] = useState('');
    const [profilePath, setProfilePath] = useState(null);
    const [isRandom, setIsRandom] = useState(false);
    const [languageList, setLanguageList] = useState([]);
    const [bloodList, setBloodList] = useState([]);
    const [religionList, setReligionList] = useState([]);
    const [casteList, setCasteList] = useState([]);
    const yearOptions = Array.from({ length: 50 }, (_, i) => ({ label: `${2024 - i}`, value: `${2024 - i}` }));
    const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
    const [previewProfilePath, setPreviewProfilePath] = useState('');
    const [isSubjectTeacher, setIsSubjectTeacher] = useState(false);
    const [isClassTeacher, setIsClassTeacher] = useState(false);
    const [assignedClass, setAssignedClass] = useState([]);
    const [assignedSubject, setAssignedSubject] = useState([]);
    const [shiftList, setShiftList] = useState([]);
    const [selectedShiftId, setSelectedShiftId] = useState(null);
    const [shiftTime, setShiftTime] = useState('-');
    const [classTeacherName, setClassTeacherName] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                Loader.show();
                const loginData = CommonConfig.loginData();
                setSecurityUserID(loginData?._id);
                await getUserDetailById();
                await GetShiftList(loginData?._id);
                await GetLanguageList(loginData?._id);
                await GetBloodList(loginData?._id);
                await GetCasteList(loginData?._id);
                await GetReligionList(loginData?._id);
                await getPersonDetail();
                await getQualificationDetail();
            } catch (error) {
                Loader.hide();
                console.error('Error in useEffect:', error);
            } finally {
                Loader.hide();
            }
        };
        fetchData();
    }, []);

    const onReferesh = async () => {
        await getUserDetailById();
        await GetShiftList(securityUserID);
        await GetLanguageList(securityUserID);
        await GetBloodList(securityUserID);
        await GetCasteList(securityUserID);
        await GetReligionList(securityUserID);
        await getPersonDetail();
        await getQualificationDetail();
    }

    const GetShiftList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getShiftList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setShiftList(res.data.map(x => ({
                        label: `${x?.ShiftName}(${formattedTime(x?.ShiftStart)} - ${formattedTime(x?.ShiftEnd)})`,
                        value: x?._id
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const formattedTime = (date) => {
        return new Date(date).toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        })
    };

    const GetLanguageList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getLanguageList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setLanguageList(res.data.map(x => ({
                        ...x,
                        label: x?.LanguageName,
                        value: x?._id
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    };

    const GetBloodList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getBloodList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setBloodList(res.data.map(x => ({
                        label: x?.BloodName,
                        value: x?._id
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetCasteList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getCasteList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setCasteList(res.data.map(x => ({
                        label: x?.CasteName,
                        value: x?._id
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetReligionList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getReligionList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setReligionList(res.data.map(x => ({
                        label: x?.ReligionName,
                        value: x?._id
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const addQualification = () => {
        setQualifications([...qualifications, {
            id: Date.now(),
            _id: '',
            degree: '',
            passingYear: '',
            college: '',
            university: '',
            percentage: ''
        }]);
    };

    const removeQualification = (id) => {
        setQualifications(qualifications.filter(q => q._id !== id));
    };

    const updateQualification = (id, field, value) => {
        const updatedQualifications = qualifications.map(q =>
            q.id === id ? { ...q, [field]: value } : q
        );
        setQualifications(updatedQualifications);
    };

    const getUserDetailById = async (ID) => {
        try {
            let data = {
                id: props?.location?.state?.userId,
            };
            await api
                .post(APIConstant.path.getUserById, data)
                .then(async (response) => {
                    let res = await response;
                    if (res.success) {
                        Loader.hide();
                        setFullName(res?.data?.Name);
                        setMobileNumber(res?.data?.Phone);
                        setEmailAddress(res?.data?.Email);
                        setProfilePath(res?.data?.ProfilePhotoUrl ? `${apiBase}/UserProfilePic/${res?.data?.ProfilePhotoUrl}` : null);
                    }
                })
                .catch((err) => {
                    Loader.hide();
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    };

    const getPersonDetail = async (ID) => {
        try {
            let data = {
                id: props?.location?.state?.userId,
            };
            await api
                .post(APIConstant.path.GetPersonalDetailById, data)
                .then(async (response) => {
                    let res = await response;
                    if (res.success) {
                        setPersonalDetailId(res?.data?._id);
                        setTeacherId(res?.data?.ProfileId);
                        setAadharNo(res?.data?.AadharNo);
                        setSelectedGender(res?.data?.Gender);
                        setDob(res?.data?.DOB ? new Date(res?.data?.DOB) : null);
                        setAge(res?.data?.Age);
                        setSelectedNationality(res?.data?.Nationality);
                        setSelectedBloodGroup(res?.data?.BloodGroup);
                        setSelectedReligion(res?.data?.Religion);
                        setSelectedCategory(res?.data?.Category);
                        setLanguages(res?.data?.Language ? res?.data?.Language.split(',') : []);
                        setAlternateMobileNumber(res?.data?.AlternateContact);
                        setCurrentAddress(res?.data?.CurrentAddress);
                        setPermanentAddress(res?.data?.PermanentAddress ?? '');
                        setPinCode(res?.data?.PinCode);
                        setDoj(res?.data?.DOJ ? new Date(res?.data?.DOJ) : null);
                        setExperience(res?.data?.JobExperience)
                        setLastOrganzation(res?.data?.LastOrganzation);
                        setPosition(res?.data?.LastJobPosition);
                        setIsSubjectTeacher(res?.data?.IsSubjectTeacher);
                        setIsClassTeacher(res?.data?.IsClassTeacher);
                        setAssignedClass(res?.data?.AssignedClass ? res?.data?.AssignedClass.split(',') : []);
                        setAssignedSubject(res?.data?.AssignedSubject ? res?.data?.AssignedSubject.split(',') : []);
                        setSelectedShiftId(res?.data?.ShiftId);
                        setShiftTime(`${res?.data?.ShiftName}(${formattedTime(res?.data?.ShiftStart)} - ${formattedTime(res?.data?.ShiftEnd)})`);
                        setClassTeacherName(res?.data?.AssignedClassTeacherName);
                    }
                    Loader.hide();
                })
                .catch((err) => {
                    Loader.hide();
                    console.log(err);
                });
        } catch (err) {
            Loader.hide();
            console.log(err);
        }
    };

    const getQualificationDetail = async (ID) => {
        try {
            let data = {
                id: props?.location?.state?.userId,
            };
            await api
                .post(APIConstant.path.GetQualificationDetailById, data)
                .then(async (response) => {
                    let res = await response;
                    if (res.success) {
                        Loader.hide();
                        let resData = res?.data;
                        if (resData.length) {
                            const qualificationsData = resData.map((x) => ({
                                id: Date.now(),
                                _id: x?._id,
                                degree: x?.DegreeName,
                                passingYear: x?.PassingYear,
                                college: x?.CollegeName,
                                university: x?.University,
                                percentage: x?.Percentage
                            }));
                            setQualifications(qualificationsData);
                        }
                    }
                })
                .catch((err) => {
                    Loader.hide();
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    };

    const saveDetails = async (e) => {
        await AddEditUserDetail();
        await AddEditPersonalDetail();
        await AddEditQualificationDetail();
        await onReferesh();
    }

    const AddEditUserDetail = async () => {
        try {
            const formData = new FormData();
            formData.append("id", props?.location?.state?.userId);
            formData.append("Name", fullName);
            formData.append("Email", emailAddress);
            formData.append("Phone", mobileNumber);
            if (profilePath && profilePath.type) {
                formData.append("userProfilePic", profilePath);
            } else if (CommonConfig.isEmpty(profilePath)) {
                formData.append("userProfilePic", '');
            }
            Loader.show();
            await api.post(APIConstant.path.AddUpdateUser, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
                .then(async (response) => {
                    let res = response;
                    if (res.success) {
                        // Toast.success({ message: 'User details updated successfully' });
                    } else {
                        Toast.error({ message: res.message });
                    }
                    Loader.hide();
                })
                .catch((err) => {
                    console.error(err);
                    Toast.error({ message: 'Failed to update user details' });
                    Loader.hide();
                });
        } catch (err) {
            console.log(err);
            Toast.error({ message: 'An error occurred' });
            Loader.hide();
        }
    };

    const AddEditPersonalDetail = async (e) => {
        try {
            let data = {
                id: personalDetailId,
                UserId: props?.location?.state?.userId,
                AadharNo: aadharNo,
                Gender: selectedGender,
                DOB: dob ? moment(dob).format("YYYY-MM-DD") : null,
                Age: age,
                Nationality: selectedNationality,
                BloodGroup: selectedBloodGroup,
                Religion: selectedReligion,
                Category: selectedCategory,
                Language: languages.length > 1 ? languages.join(',') : languages[0],
                AlternateContact: alternateMobileNumber,
                PinCode: pinCode,
                CurrentAddress: currentAddress,
                PermanentAddress: permanentAddress,
                DOJ: doj ? moment(doj).format("YYYY-MM-DD") : null,
                JobExperience: experience,
                LastOrganzation: lastOrganzation,
                LastJobPosition: position,
                ShiftId: selectedShiftId,
                CreatedBy: securityUserID
            };
            Loader.show();
            await api.post(APIConstant.path.AddPersonalDetail, data).then(async response => {
                let res = response;
                if (res.success) {
                    Toast.success({ message: 'Details Updated' });
                    Loader.hide();
                } else {
                    Toast.error({ message: res.message });
                    Loader.hide();
                }
            })
                .catch((err) => {
                    Loader.hide();
                });
        } catch (err) {
            console.log(err);
            Loader.hide();
        }
    };

    const AddEditQualificationDetail = async (e) => {
        try {
            if (qualifications) {
                qualifications.map(async (x) => {
                    let data = {
                        id: x._id,
                        UserId: props?.location?.state?.userId,
                        DegreeName: x.degree,
                        PassingYear: x.passingYear,
                        CollegeName: x.college,
                        University: x.university,
                        Percentage: x.percentage,
                        CreatedBy: securityUserID
                    };
                    Loader.show();
                    await api.post(APIConstant.path.AddQualificationDetail, data).then(async response => {
                        let res = response;
                        if (res.success) {
                            // Toast.success({ message: 'Qualification details updated' });
                            Loader.hide();
                        } else {
                            Toast.error({ message: res.message });
                            Loader.hide();
                        }
                    })
                        .catch((err) => {
                            Loader.hide();
                        });
                })

            }
        } catch (err) {
            console.log(err);
            Loader.hide();
        }
    };

    const handleChange = (e, type) => {
        const value = e.target.value;
        const alphanumericValue = value.replace(/[^a-zA-Z0-9\s!@#$%^&*]/g, '');
        const numericValue = value.replace(/[^0-9]/g, '');
        if (type === 'FullName') {
            setFullName(alphanumericValue);
        } else if (type === 'MobileNo') {
            setMobileNumber(numericValue);
        } else if (type === 'AlternateNo') {
            setAlternateMobileNumber(numericValue);
        } else if (type === 'AadharNo') {
            setAadharNo(numericValue);
        } else if (type === 'Age') {
            setAge(numericValue);
        } else if (type === 'PinCode') {
            setPinCode(numericValue);
        } else if (type === 'Experience') {
            setExperience(numericValue);
        } else if (type === 'LastOrganization') {
            setLastOrganzation(alphanumericValue);
        } else if (type === 'LastJob') {
            setPosition(alphanumericValue);
        }
    };

    const handleCheckBox = (e, type) => {
        setIsRandom(e.checked);
        if (e.checked) {
            setOldPermanentAddress(permanentAddress);
            setPermanentAddress(currentAddress);
        } else {
            setPermanentAddress(oldPermanentAddress);
            setOldPermanentAddress('');
        }
    }

    const handleEmailChange = (e) => {
        const value = e.target.value;
        const maxLength = 50;
        if (value.length <= maxLength) {
            setEmailAddress(value);
        }
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailPattern.test(value)) {
            setEmailErr(true);
            setEmailErrText('Please enter a valid email address.');
        } else {
            setEmailErr(false);
            setEmailErrText('');
        }
    };

    const handleFileSelect = (e) => {
        const selectedFile = e.files[0];
        if (selectedFile) {
            const validFormats = ['image/jpeg', 'image/png', 'image/jpg', 'image/svg+xml'];
            if (validFormats.includes(selectedFile.type)) {
                setProfilePath(selectedFile);
                const fileURL = URL.createObjectURL(selectedFile);
                setPreviewProfilePath(fileURL);
            } else {
                Toast.error({ message: 'Invalid file format. Please select a JPEG, SVG or PNG image.' });
            }
        }
    };

    const renderFooter = () => (
        <div className="dialog-footer1 p-d-flex p-ai-center p-jc-end">
            <Button
                label="Cancel"
                className="primary-btn1"
                onClick={handleClose}
            />
            <Button
                label="Delete"
                className="primary-btn"
                onClick={() => {
                    handleClose();
                    setProfilePath("")
                    setPreviewProfilePath('');
                }}
            />
        </div>
    );

    const handleClose = () => {
        setIsDeleteConfirmation(false)
    };

    const calculateAge = (dob) => {
        if (!dob) return '';
        const today = new Date();
        const birthDate = new Date(dob);

        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();

        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    };

    const handleDobChange = (e) => {
        const selectedDob = e.value;
        setDob(selectedDob);
        setAge(calculateAge(selectedDob));
    };

    return (
        <div className="wayment-owner-dashboard e-weighment-dashoard">
            <div className="e-profile-header">
                <div className="e-profile-box">
                    <div className="e-profile-main">
                        <img
                            src={!CommonConfig.isEmpty(previewProfilePath) ? previewProfilePath : !CommonConfig.isEmpty(profilePath) ? profilePath : userImage}
                            alt="logo"
                            style={{ width: "150px", height: "150px", borderRadius: "8px" }}
                            className="e-profile"
                        />
                        <div className='p-d-flex p-ai-center p-jc-between'>
                            {props?.location?.state?.isEdit ?
                                <FileUpload
                                    id="profilePath"
                                    mode="basic"
                                    name="profilePath"
                                    accept="image/*"
                                    maxFileSize={1000000}
                                    chooseLabel="Profile"
                                    auto={false}
                                    onSelect={(e) => handleFileSelect(e)}
                                /> : null}
                            {!CommonConfig.isEmpty(profilePath) && props?.location?.state?.isEdit && (
                                <div
                                    className="e-btn"
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() => setIsDeleteConfirmation(true)}
                                >
                                    <i className="pi pi-trash" style={{ fontSize: "18px", color: "red" }}></i>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="e-profile-info">
                        <div>
                            <h2 className='p-text-justify p-mt-1'>{fullName}</h2>
                            <div className='p-d-flex p-ai-center '>
                                <div className='profile-text-box p-mx-2'><p className='profile-text'>Shift Time - {shiftTime}</p></div>
                                <div className='profile-text-box p-mx-2'><p className='profile-text'>Teacher ID - {teacherId}</p></div>
                            </div>
                        </div>
                        {/* <div className='p-d-flex p-ai-center p-jc-between'>
                            <div className='profile-cv-box p-d-flex p-ai-center p-jc-between'>
                                <span className='profile-cv-text'>CV -    </span>
                                <div className='p-d-flex p-ai-center p-jc-between'>
                                    <i className="pi pi-eye p-mr-3"></i>
                                    <i className="pi pi-download"></i>
                                </div>
                            </div>
                            <div className="profile-transport-box">
                                <span className='profile-t-text'>Transport Facility</span>
                            </div>
                            <div>
                                <Button label="Using" className="e-border-btn" />
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="p-d-flex p-ai-center p-jc-between">
                    <div className='p-d-flex p-flex-column p-ai-center'>
                        <h4 className='p-mt-4 text-center p-mb-2'>Designation</h4>
                        <div className="p-d-flex p-ai-center">
                            {isClassTeacher && <div className="box class-teacher-box">Class Teacher</div>}
                            {isSubjectTeacher && <div className="box subject-teacher-box">Subject Teacher</div>}
                        </div>
                    </div>
                    <div className='p-d-flex p-flex-column p-ai-center'>
                        <h4 className='p-mt-4 p-mb-2'>Assigned Class & Section</h4>
                        <div className="p-d-flex p-ai-center">
                            {assignedClass?.map((x, idx) => {
                                const isClassTeacher = x === classTeacherName;
                                return (
                                    <div
                                        style={{
                                            padding: "5px 10px",
                                            border: "1px solid #ccc",
                                            margin: '5px',
                                            borderRadius: "5px",
                                            backgroundColor: x === classTeacherName ? "#ffe0b3" : "#f0f0f0",
                                            fontWeight: x === classTeacherName ? "bold" : "normal",
                                        }}
                                        key={idx}
                                    >
                                        {x}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className='p-d-flex p-flex-column p-ai-center'>
                        <h4 className='p-mt-4 p-mb-2'>Subject Taught</h4>
                        <div className="p-d-flex p-ai-center">
                            {assignedSubject?.map((x, idx) => {
                                return <Button label={x} className="p-my-0 p-mx-2" key={idx} />
                            })}
                        </div>
                    </div>
                </div>
            </div>

            <TabView className='p-mt-3'>
                <TabPanel header="Personal Details">
                    <div className="form-section p-mb-0">
                        <div className="p-grid">
                            <div className="p-col-12 p-md-4">
                                <label className='p-d-block' htmlFor="fullName">Full Name</label>
                                <InputText className='p-w-100' id="fullName" maxLength={20} value={fullName} disabled={!props?.location?.state?.isEdit} onChange={(e) => handleChange(e, 'FullName')} />
                                <span className="p-error block" style={{ color: 'red' }}>
                                    {emailErr ? emailErrText : null}
                                </span>
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label className='p-d-block' htmlFor="aadhar">Aadhar</label>
                                <div className="p-inputgroup p-d-flex p-ai-lg-baseline">
                                    <InputText className='p-w-100 p-h-100' id="aadhar" value={aadharNo} maxLength={12} disabled={!props?.location?.state?.isEdit} onChange={(e) => handleChange(e, 'AadharNo')} />
                                    <Button className='p-m-0' label="Verify" disabled={!props?.location?.state?.isEdit} />
                                </div>
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label className='p-d-block' htmlFor="gender">Gender</label>
                                <Dropdown className='p-w-100' id="gender" disabled={!props?.location?.state?.isEdit} value={selectedGender} options={genderOptions} onChange={(e) => setSelectedGender(e.value)} placeholder="Select Gender" />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label className='p-d-block' htmlFor="dob">Date of Birth</label>
                                <Calendar className='p-w-100' id="dob" value={dob} onChange={handleDobChange} disabled={!props?.location?.state?.isEdit} dateFormat='dd/mm/yy' maxDate={new Date()} />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label className='p-d-block' htmlFor="age">Age</label>
                                <InputText className='p-w-100' id="age" maxLength={2} value={age} disabled={!props?.location?.state?.isEdit} onChange={(e) => handleChange(e, 'Age')} />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label className='p-d-block' htmlFor="nationality">Nationality</label>
                                <Dropdown className='p-w-100' id="nationality" value={selectedNationality} disabled={!props?.location?.state?.isEdit} options={nationalityOptions} onChange={(e) => setSelectedNationality(e.value)} placeholder="Select Nationality" />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label className='p-d-block' htmlFor="bloodGroup">Blood Group</label>
                                <Dropdown className='p-w-100' id="bloodGroup" value={selectedBloodGroup} options={bloodList} disabled={!props?.location?.state?.isEdit} onChange={(e) => setSelectedBloodGroup(e.value)} placeholder="Select Blood Group" />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label className='p-d-block' htmlFor="religion">Religion</label>
                                <Dropdown className='p-w-100' id="religion" value={selectedReligion} options={religionList} disabled={!props?.location?.state?.isEdit} onChange={(e) => setSelectedReligion(e.value)} placeholder="Select Religion" />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label className='p-d-block' htmlFor="category">Category</label>
                                <Dropdown className='p-w-100' id="category" value={selectedCategory} options={casteList} disabled={!props?.location?.state?.isEdit} onChange={(e) => setSelectedCategory(e.value)} placeholder="Select Category" />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label className='p-d-block' htmlFor="languages">Languages Spoken</label>
                                <MultiSelect className='p-w-100' id="languages" value={languages} options={languageList} disabled={!props?.location?.state?.isEdit} onChange={(e) => setLanguages(e.value)} placeholder="Select language" filter display='chip' />
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="Contact Details">
                    <div className="form-section p-mb-0">
                        <div className="p-grid">
                            <div className="p-col-12 p-md-4">
                                <label className='p-d-block' htmlFor="emailId">Email Address</label>
                                <InputText className='p-w-100' id="emailId" value={emailAddress} disabled={!props?.location?.state?.isEdit} onChange={(e) => handleEmailChange(e)} />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label className='p-d-block' htmlFor="contactNo">Contact Number</label>
                                <div className="p-inputgroup">
                                    <InputText id="contactNo" maxLength={10} value={mobileNumber} disabled={!props?.location?.state?.isEdit} onChange={(e) => handleChange(e, 'MobileNo')} />
                                </div>
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label className='p-d-block' htmlFor="emrContactNo">Emergency Contact Number</label>
                                <div className="p-inputgroup">
                                    <InputText id="emrContactNo" maxLength={10} disabled={!props?.location?.state?.isEdit} value={alternateMobileNumber} onChange={(e) => handleChange(e, 'AlternateNo')} />
                                </div>
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label className='p-d-block' htmlFor="pinCode">Pin Code</label>
                                <InputText className='p-w-100' id="pinCode" value={pinCode} maxLength={6} disabled={!props?.location?.state?.isEdit} onChange={(e) => handleChange(e, 'PinCode')} />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label className='p-d-block' htmlFor="currentAddress">Current Address</label>
                                <InputTextarea id="currentAddress" maxLength={100} value={currentAddress} type='textArea' rows={1} cols={30} onChange={(e) => setCurrentAddress(e.target.value)} disabled={!props?.location?.state?.isEdit} />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <div className="p-d-flex p-ai-center p-mt-4">
                                    <Checkbox
                                        inputId="randomCheckbox"
                                        checked={isRandom}
                                        onChange={(e) => handleCheckBox(e)}
                                    />
                                    <label className='p-d-block' htmlFor="randomCheckbox" style={{ marginLeft: '8px' }}>Permanent address same as current</label>
                                </div>
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label className='p-d-block' htmlFor="permanentAddress">Permanent Address</label>
                                <InputTextarea id="permanentAddress" maxLength={100} value={permanentAddress} rows={1} cols={30} onChange={(e) => setPermanentAddress(e.target.value)} disabled={!props?.location?.state?.isEdit} />
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="Employment Details">
                    <div className="form-section p-mb-0">
                        <div className="p-grid">
                            <div className="p-col-12 p-md-4">
                                <label htmlFor="doj">Date of Joining</label>
                                <Calendar className='p-w-100 input-height p-m-0' id="doj" value={doj} onChange={(e) => setDoj(e.value)} disabled={!props?.location?.state?.isEdit} dateFormat='dd/mm/yy' />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label htmlFor="experience">Years of Experience</label>
                                <InputText className='p-w-100' id="experience" maxLength={2} value={experience} onChange={(e) => handleChange(e, 'Experience')} disabled={!props?.location?.state?.isEdit} />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label htmlFor="lastOrganization">Last Organization</label>
                                <InputText className='p-w-100' id="lastOrganization" maxLength={40} value={lastOrganzation} onChange={(e) => handleChange(e, 'LastOrganization')} disabled={!props?.location?.state?.isEdit} />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label htmlFor="lastJobPosition">Last Job Position</label>
                                <InputText className='p-w-100' id="lastJobPosition" maxLength={30} value={position} onChange={(e) => handleChange(e, 'LastJob')} disabled={!props?.location?.state?.isEdit} />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label className='p-d-block' htmlFor="shift">Shift</label>
                                <Dropdown className='p-w-100' id="shiftId" value={selectedShiftId} options={shiftList} disabled={!props?.location?.state?.isEdit} onChange={(e) => setSelectedShiftId(e.value)} placeholder="Select Shift" />
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="Qualification Details">
                    <div className="qualification-details">
                        {qualifications.map((qualification, index) => (
                            <div key={qualification.id} className="qualification-section">
                                <div className='p-d-flex p-ai-center p-jc-between'>
                                    <h4>Qualification {index + 1}</h4>
                                    <Button
                                        icon="pi pi-trash"
                                        className="p-button-rounded p-button-danger p-button-sm p-d-flex p-m-0"
                                        disabled={!props?.location?.state?.isEdit}
                                        onClick={() => removeQualification(qualification._id)}
                                    />
                                </div>
                                <hr className='p-m-0' />
                                <div className="p-grid p-mt-2">
                                    <div className="p-col-4">
                                        <label htmlFor={`degree${qualification.id}`}>Degree</label>
                                        <div className="p-field">
                                            <InputText
                                                id={`degree${qualification.id}`}
                                                value={qualification.degree}
                                                disabled={!props?.location?.state?.isEdit}
                                                onChange={(e) => updateQualification(qualification.id, 'degree', e.target.value)}
                                                className='p-w-100'
                                            />
                                        </div>
                                    </div>
                                    <div className="p-col-4">
                                        <label htmlFor={`passingYear${qualification.id}`}>Passing Year</label>
                                        <div className="p-field">
                                            <Dropdown
                                                id={`passingYear${qualification.id}`}
                                                value={qualification.passingYear}
                                                options={yearOptions}
                                                disabled={!props?.location?.state?.isEdit}
                                                onChange={(e) => updateQualification(qualification.id, 'passingYear', e.value)}
                                                placeholder="Select Year"
                                                className='p-w-100'
                                            />
                                        </div>
                                    </div>
                                    <div className="p-col-4">
                                        <label htmlFor={`college${qualification.id}`}>College</label>
                                        <div className="p-field">
                                            <InputText
                                                id={`college${qualification.id}`}
                                                value={qualification.college}
                                                disabled={!props?.location?.state?.isEdit}
                                                onChange={(e) => updateQualification(qualification.id, 'college', e.target.value)}
                                                className='p-w-100'
                                            />
                                        </div>
                                    </div>
                                    <div className="p-col-4">
                                        <label htmlFor={`university${qualification.id}`}>University</label>
                                        <div className="p-field">
                                            <InputText
                                                id={`university${qualification.id}`}
                                                value={qualification.university}
                                                disabled={!props?.location?.state?.isEdit}
                                                onChange={(e) => updateQualification(qualification.id, 'university', e.target.value)}
                                                className='p-w-100'
                                            />
                                        </div>
                                    </div>
                                    <div className="p-col-4">
                                        <label htmlFor={`percentage${qualification.id}`}>Percentage</label>
                                        <div className="p-field">
                                            <InputText
                                                id={`percentage${qualification.id}`}
                                                value={qualification.percentage}
                                                disabled={!props?.location?.state?.isEdit}
                                                // onChange={(e) => updateQualification(qualification.id, 'percentage', e.target.value)}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (/^\d*\.?\d*$/.test(value)) {
                                                        updateQualification(qualification.id, 'percentage', value);
                                                    }
                                                }}
                                                onKeyPress={(e) => {
                                                    const char = String.fromCharCode(e.which);
                                                    if (!/[\d.]/.test(char)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                className='p-w-100'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {props?.location?.state?.isEdit ?
                            <div className="add-qualification">
                                <Button label="Add Qualification" icon="pi pi-plus" className="p-button-rounded p-button-primary" onClick={addQualification} />
                            </div> : null}
                    </div>
                </TabPanel>
            </TabView>

            <div className="p-d-flex p-ai-center p-jc-end">
                {props?.location?.state?.isEdit ?
                    <Button label="Save" className="p-my-0 e-sction-btn p-mr-2" onClick={() => saveDetails()} /> : null}
                <Button label="Back" className="p-my-0 p-mx-0 e-border-btn" onClick={() => navigate('/teacher-master')} />
            </div>
            <Dialog
                visible={isDeleteConfirmation}
                onHide={handleClose}
                header={
                    <div className="p-d-flex p-jc-between p-ai-center">
                        <span>Delete Confirmation</span>
                    </div>
                }
                footer={renderFooter()}
                draggable={false}
                dismissableMask
                style={{ width: '450px' }}
            >
                <DeleteConfirmation />
            </Dialog>
        </div >
    );
}

export default ViewEditTeacherDetails;
